.loader {
	position: relative;
	width: 1.5rem;
	height: 1.5rem;
}
.loader {
	&:before, &:after {
		content: "";
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		margin: 3px;
		border: 3px solid $grayLine;
		border-radius: 50%;
		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $grayLine $grayLine transparent transparent;
	}
}

.loader:before {
	animation-delay: -0.4s;
}
.loader:after {
	animation-delay: -0.15s;
}
@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}