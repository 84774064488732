.filter-topics {
	&-wrap {
		padding: 1rem 1.75rem 0.25rem 1.75rem;

		&-border {
			border-bottom: 2px solid $grayLine;
			padding-bottom: 0.9rem;
		}
	}
}

// perspectives switch

.switch {
	display: flex;
	justify-content: center;
	align-items: center;

	&-wrap {
		border-bottom: 2px solid $grayLine;
	}

	&-label {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 0.7rem;
		letter-spacing: 0.1em;
		color: $grayText;
	}

	&-items {
		display: flex;
		margin-bottom: -2px;
	}

	&-item {
		margin-left: 2rem;

		&:first-of-type {
			margin-left: 1.75rem;
		}

		&-label {
			display: inline-block;
			position: relative;
			font-family: $baseFont;
			color: $blue;
			white-space: nowrap;
			line-height: 2.5rem;
			@extend .title;
			font-weight: 500;


			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background: $green;
				position: absolute;
				left: 0;
				bottom: 0;
				transform: scaleX(0);
				transition: transform 0.05s ease-in;
			}

			&:hover, &:active, &:focus {
				text-decoration: underline;
			}
		}

		&-input {
			position: absolute;
			opacity: 0;
			pointer-events: none;

			&:checked ~ .switch-item-label {
				color: $darkBlue;

				&:after {
					transform: scaleX(1);
				}
			}
		}
	}
}

.metrics {
	&-wrap {
		padding: 3.25rem 0 3.75rem 0;
	}

	&-wrap-export {
		padding: 1.5rem 0 3.75rem 0;
	}

	&-export {
		margin-left: auto;
		margin-bottom: 1.5rem;
	}
}

// metric groups checkboxes

.metric-group-check {
	&-items-wrap {
		border-top: 2px solid $grayLine;
		margin-bottom: 1rem;
	}

		border-bottom: 2px solid $grayLine;

		&-name {
			font-weight: 600;
			font-size: 0.8rem;
			color: $blue;
			padding: 0.6rem 1rem 0.6rem 1.75rem;
			margin: 0;
			position: relative;

			&:before {
				content: "";
				display: block;
				width: 1.1rem;
				height: 1.1rem;
				background: url('../svg/src/plus-blue.svg') center center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				left: 0.35rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				cursor: pointer;
			}

			&:hover, &:active, &:focus {
				text-decoration: underline;

				&:before {
					background-image: url('../svg/src/plus-green.svg');
				}
			}
		}

		&-name.active {
			color: $darkBlue;

			&:before {
				background-image: url('../svg/src/minus-green.svg');
			}
		}

		&-items {
			display: none;

			padding: 0.15rem 0 0.8rem 1.75rem;
		}

		&-item {
			&:not(:last-child) {
				padding-bottom: 0.5rem;
			}
		}
}

// metric groups

.metric-group {
	margin-bottom: 1rem;
	background: $white;

	animation: fadeIn 0.1s ease-in;

	&-inner {
		padding: 1rem 1.25rem 1rem 1.5rem;
	}

	&-header {
		display: flex;
		margin-bottom: 0.75rem;
	}

	&-btns {
		display: flex;
		margin-left: auto;

		.btn {

			.tooltip {
				position: absolute;
				font-size: 0.6rem;
				color: $white;
				background: $blue;
				padding: 0.75rem 1rem;
				text-transform: none;
				font-weight: 400;
				text-align: left;
				width: 20rem;
				top: calc(100% + 12px);
				left: 50%;
				transform: translateX(-50%);
				z-index: 2000;
				letter-spacing: normal;
				border-radius: 5px;
				box-shadow: 0px 6px 8px 0px rgba($darkBlue, 0.15), 0px 12px 27px 0px rgba($darkBlue, 0.1);

				visibility: hidden;
				max-width: 0;
				max-height: 0;
				opacity: 0;
				transition: opacity 0.1s ease-in;
				white-space: nowrap;
				text-overflow: clip;

				&:after {
					@include triangleTop(14px, 8px, $blue);
					position: absolute;
					left: 50%;
					top: -7px;
					transform: translateX(-50%);
				}

				a {
					color: $white;
					text-decoration: underline;

					&:hover, &:active, &:focus {
						color: $white;
						text-decoration: none;
					}
				}
			}

			&:hover, &:active, &:focus {
				.tooltip {
					visibility: visible;
					max-width: none;
					max-height: none;
					opacity: 1;
					white-space: normal;
				}
			}
		}

		.btn-set {
			display: flex;
			margin-left: 0.5rem;

			.btn {
				margin-left: 0;
				&:not(:first-child) {
					border-left-width: 1px;
				}

				&:not(:last-child) {
					border-right-width: 1px;
				}

				&-active {
					border-bottom-color: transparent;
					border-top-color: $green;
					color: $baseFontColor;

					&:hover, &:active {
						color: $white;
					}
				}
			}
		}
	}

	&-head {
		font-weight: 600;
		font-size: 1.1rem;
		margin-bottom: 0;
	}

	&-subhead {
		font-weight: 500;
		color: $blue;
		font-size: 0.7rem;
		margin-bottom: 1.25rem;
		margin-top: -0.5rem;
	}

	&-select-wrapper {
		margin-bottom: 0.8rem;
		min-width: 16rem;
		max-width: 100%;
		width: auto;
		display: inline-block;
	}

	&-select {
		width: 100%;

		// metric groups with single option - display like simple heading, without select
		/*&-single {
			.select2-selection.select2-selection--single {
				border: none !important;
				padding: 0 !important;
				margin-top: -1.5rem;
			}

			.select-wrapper .select2-selection--single .select2-selection__arrow {
				display: none !important;
			}
		}*/
	}

	// styles used for exporting to pdf
	.pdf-export {
		padding-top: 0.5rem;

		.select2-selection.select2-selection--single {
			border: none !important;
			padding: 0 0 0.6rem 0 !important;
			margin-top: -1.25rem;
			margin-bottom: 0.5rem;
		}

		.select-wrapper .select2-selection--single .select2-selection__arrow {
			display: none !important;
		}

		.sh-trig {
			display: none;
		}
	}
}

.metric-group-text, .metric-group-text.entry {
	border-top: 2px solid $grayLine;
	padding: 1.25rem 1.6rem;
	font-size: 0.7rem;
	line-height: 1.5;
	color: $blueGray;

	&-head {
		font-size: 0.7rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
		color: $baseFontColor;
	}

	p {
		margin-bottom: 0.5em;
	}

	li {
		margin-bottom: 0.5em;
		padding-left: 1.5rem;
	}

	ul li {
		&:before {
			width: 8px;
			height: 8px;
			background: $blueGray;
			border-radius: 50%;
			top: 0.3rem;
			left: 0.5rem;
		}
	}

	ol li {
		&:before {
			color: $blueGray;
			top: 0;
			left: 0.45rem;
			font-weight: 500;
		}
	}

	&-trig {
		display: block !important;
		margin-top: -0.25rem;
	}
}

.fadeIn {

}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.metric-group-placeholder {
	background: linear-gradient(to right, $grayLine 66%, rgba(255, 255, 255, 0) 0%), linear-gradient($grayLine 66%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, $grayLine 66%, rgba(255, 255, 255, 0) 0%), linear-gradient($grayLine 66%, rgba(255, 255, 255, 0) 0%);
	background-position: top, right, bottom, left;
	background-repeat: repeat-x, repeat-y;
	background-size: 12px 2px, 2px 12px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	color: $blueGray;
	padding: 1.5rem;
	min-height: 18rem;

	&-icon {
		width: 4.1rem;
		height: 4.1rem;
		margin-bottom: 0.25rem;
	}

	&-text {
		max-width: 15rem;
		font-size: 1.1rem;
		font-weight: 600;
	}
}

.metric-group-placeholder-text-xs {
	display: none;
}


.metric-region-selection-message {

}

// metrics

.metric {
	.loader {
		display: none;
		width: 0;
		height: 0;
	}

	&-blurred {
		filter: blur(2px);
	}

	&-wrapper {
		position: relative;
	}

	&-placeholder {
		min-height: 16rem;
		position: relative;

		.loader {
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -0.75rem;
			margin-top: -1rem;
		}
	}

	&-region-selection-msg {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($white, 0.2);

		&-text {
			background: $blue;
			color: $white;
			padding: 0.75rem 1rem;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, calc(-50% - 1rem));
			border-radius: 3px;
			width: 80%;
			font-size: 0.8rem;
		}
	}

	&-comment {
		animation: fadeIn 0.25s ease-in;
	}

	&-comments {
		&-edit {
			.icon {
				margin-top: 0.1rem;
				margin-left: 0.15rem;
			}
		}
	}
}

// metric groups sticky / to anchor navigation

.metric-groups-nav {
	margin: 0 0 1rem 0;
	padding: 0;
	border-top: 2px solid $grayLine;
	position: sticky;
	position: -webkit-sticky;
	top: 1.5rem;

	li {
		margin: 0;
		padding: 0;

		a {
			display: block;
			color: $blue;
			font-weight: 600;
			font-size: 0.8rem;
			line-height: 1.3;
			padding: 0.75rem 0.5rem 0.75rem 1.5rem;
			border-bottom: 2px solid $grayLine;
			position: relative;

			&:before {
				content: "";
				display: block;
				width: 1.1rem;
				height: 1.1rem;
				background: url('../svg/src/arrow-down-blue.svg') center center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				left: 0.15rem;
				top: 0.7rem;
			}

			&.metric-groups-nav-top {
				&:before {
					background-image: url('../svg/src/chevron-double-up-blue.svg');
				}

				&:hover, &:active {
					&:before {
						background-image: url('../svg/src/chevron-double-up-green.svg');
					}
				}
			}

			&:hover, &:active {
				&:before {
					background-image: url('../svg/src/arrow-down-green.svg');
				}
			}
		}
	}


}


// boxes with summary

.summary-items {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	margin-left: -1rem;
}

.summary {
	padding-left: 1rem;
	width: 50%;
	margin-bottom: 1rem;

	&-inner {
		background: $white;
		padding: 1.15rem 1.5rem;
	}

	&-head {
		font-size: 1.1rem;
		line-height: 1.3;
		margin-bottom: 0.2rem;
		font-weight: 400;
	}

	&-number {
		font-size: 1.3rem;
		font-weight: 600;
		margin-bottom: 0.35rem;
	}
}

.summary-data {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.5rem;

	&-item {
		padding-left: 1.5rem;
	}

	&-number {
		font-size: 0.8rem;
		font-weight: 600;
		margin-bottom: 0.1rem;
	}

	&-title {
		color: $blueGray;
		font-size: 0.7rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: 0;
	}
}

// media queries

@media screen and (max-width: $md) {
	.filter-wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.metric-group-placeholder-text-xs {
		display: block;
	}

	.metric-group-placeholder-text-lg {
		display: none;
	}

	.switch {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.switch-iems {
		overflow-x: scroll;
	}

	.switch-label {
		width: 100%;
		margin-top: 1rem;
	}

	.switch-item {
		margin-left: 0 !important;

		&:not(:last-child) {
			margin-right: 1.75rem;
		}
	}

	.metric-group {
		&-header {
			flex-wrap: wrap;
		}

		&-btns {
			width: 100%;
			margin-left: 0;
			margin-top: 0.5rem;

			.btn, .btn-set {
				&:first-child {
					margin-left: 0;
				}
			}
		}

		.btn {
			.tooltip {
				left: -4.25rem;
				transform: none;
				padding: 0.5rem 0.75rem;

				&:after {
					@include triangleTop(14px, 7px, $blue);
					position: absolute;
					left: 4.75rem;
					top: -7px;
					transform: none;
				}
			}

			&:hover, &:active, &:focus {
				.tooltip {
					display: block;
					opacity: 1;
				}
			}
		}
	}
}


@media screen and (max-width: $sm) {

	.metric-group-select-wrapper {
		min-width: 0;
		width: 100%;
	}

	.summary {
		width: 100%;
	}

	.switch-items {
		display: flex;
		overflow-x: auto;
	}
}

@media screen and (max-width: $xs) {
	.filter-topics-wrap {
		padding-top: 0;
	}

	.switch-item-label {
		font-size: 0.6rem;
	}

	.metric-group {
		&-header {
			flex-wrap: wrap;
		}

		&-btns {
			width: 100%;
			margin-left: 0;
			margin-top: 0.5rem;

			.btn, .btn-set {
				&:first-child {
					margin-left: 0;
				}
			}
		}

		.btn {
			.tooltip {
				max-width: calc(100vw - 4rem);
			}
		}
	}
}