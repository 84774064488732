.modal-opened, .dropdown-opened {
	position: relative;
	&:before {
		content: "";
		display: block;
		background: $overlay;
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;

		animation-name: modalFadeIn;
  		animation-duration: 0.05s;
  		animation-timing-function: ease-in;
	}
}

.modal-opened {
	.header {
		z-index: 800;
	}
}

@keyframes modalFadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

.content {
	padding: 0;
	background: $gray;

	/* sticky footer */
	flex: 1 0 auto;
}

.article {
	& > .row:not(:first-child) {
		margin-top: 1.5rem;
	}

	& > .row.anchor {
		&:not(:first-child) {
			margin-top: 0.75rem;
			padding-top: 0.75rem;
		}
		&:first-child {
			margin-top: -0.75rem;
			padding-top: 0.75rem;
		}
	}
}

/* Block */

.block {
	padding: 3rem 0;

	&-narrow {
		padding: 1.75rem 0;
	}

	&-alt {
		background: $blue;
		color: $white;

		a {
			color: $white;
		}
	}
}

.block.block-alpha {
	padding-top: 0;
}

.block.block-omega {
	padding-bottom: 0;
}

.block-bg {
	position: relative;
	overflow: hidden;

	&-img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			min-width: 100%;
			min-height: 100%;
			max-width: none;
			width: auto;
		}
	}
}

.section-head {
	text-align: center;
}

.box {
	padding: 1.75rem;
	margin-bottom: 2rem;

	&-white {
		background: $white;
		color: $blueGray;
	}

	&-head {
		margin-bottom: 0.5rem;
	}
}

/* Teaser */

.teaser {
	background: $blue;
	color: $white;
	padding: 2rem 0;

	&-breadcrumb {
		padding-top: 0;
	}

	&-tabs {
		padding: 2rem 0 0 0;
	}

	&-btns {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		margin-bottom: -0.5rem;
	}

	.btn {
		margin-left: 1rem;
		margin-bottom: 0.5rem;
	}

	h1 {
		font-size: 1.8rem;
		color: $white;
		margin-bottom: 0;
	}

	.suphead {
		color: $green;
		font-size: 0.7rem;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 0.35rem;
		letter-spacing: 0.1em;
	}
}

.teaser-hp {
	//TODO remove min-height
	min-height: 27rem;
	background: $darkBlue;
	color: $white;
	padding: 5rem 0 7rem 0;
	position: relative;
	overflow: hidden;

	h1 {
		color: $white;
		font-size: 2rem;
		margin-bottom: 1.75rem;
	}

	&-text {
		color: $green;
		font-size: 1rem;
		position: relative;

		p {
			margin-bottom: 0.8rem;
		}

		p:first-of-type {
			font-size: 1.1rem;
		}

		&-content {
			position: relative;
		}
	}

	&-circles {
		width: 1180px;
		max-width: none;
		position: absolute;
		left: -370px;
		top: -497px;
	}

	&-chart {
		width: 640px;
		max-width: none;
		position: absolute;
		right: -270px;
		top: -10px;
	}

	&-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, calc(-50% - 1rem));
			min-width: 100%;
			min-height: 100%;
			max-width: none;
			width: auto;
		}
	}
}

/* Breadcrumb navigation */

.breadcrumb {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;

	&-wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0.6rem 0 0.85rem 1.7rem;
	}

	&-title {
		font-size: 0.6rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $white;
		margin-bottom: 0.2rem;
		display: inline-block;
		margin-right: 0.3rem;
	}

	li {
		font-size: 0.7rem;
		color: $lightBlue;
		margin-bottom: 0.2rem;
		padding-left: 0;
		line-height: 1.3;
		letter-spacing: 0.02em;

		&:not(:last-of-type) {
			padding-right: 1.5rem;
			position: relative;

			&:after {
				content: "";
				display: block;
				width: 1rem;
				height: 1rem;
				background: url('../svg/src/chevron-right-light-blue.svg') center center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				right: 0.25rem;
				top: 0;
			}
		}

		a {
			line-height: 1.3;
			display: inline-block;
		}
	}
}


/* Pager */

.pager {
	margin: 1.75em 0 0 0;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	ul {
		display: flex;
		margin: 0;
		justify-content: center;
		align-items: center;

		li {
			display: inline-block;
			padding: 0;
			background: none;
			margin: 0 0.25rem;
			font-size: 0.7rem;
			color: $darkBlueGray;

			a:not(.btn) {
				color: $blue;
				display: inline-block;
				font-weight: 500;
				width: 1.75rem;
				height: 1.75rem;
				line-height: 1.6rem;
				text-align: center;
				border: 2px solid $grayLine;
				text-decoration: none;

				&:hover, &:active, &:focus {
					background: $blue;
					border-color: $blue;
					color: $white;
					text-decoration: none;
				}

				&.pager-active {
					border-color: $blue;
					text-decoration: none;
				}
			}
		}
	}

	&-prev {
		margin-right: 1.25rem;
	}

	&-next {
		margin-left: 1.25rem;
	}

	.pager-first .pager-dots {
		display: inline-block;
		margin: 0 0.5rem 0 0.8rem;
	}
	.pager-last .pager-dots {
		display: inline-block;
		margin: 0 0.8rem 0 0.5rem;
	}

	.btn {
		padding: 0.4rem 1rem;
		padding: 0.35rem 1.5rem 0.25rem 1.5rem;
		height: 1.75rem;
		text-transform: none;
		font-size: 0.7rem;
		letter-spacing: normal;

		.icon {
			width: 1.2rem;
			height: 1.2rem;
			margin: -0.2rem -0.25rem 0 -0.25rem;
		}
	}
}

/* search */

.search-block {
	background: $green;
	color: $white;
	padding: 0 0 3.25rem 0;
	box-shadow: 0px 6px 8px 0px rgba($blue, 0.15);

	&-inner {
		display: flex;
	}

	&-text {
		width: 30%;
	}

	&-head {
		font-size: 1.1rem;
		line-height: 1.2;
		font-weight: 600;
		color: $white;
		margin-bottom: 0;
		margin-top: -0.15rem;

		span {
			font-size: 0.8rem;
			line-height: 1.3;
			font-weight: 400;
			display: inline-block;
		}
	}

	&-form {
		width: 70%;
		padding-left: 1rem;
	}
}

.topics-nav {
	margin-left: -1.5rem;
	margin-top: -5rem;
	margin-bottom: 0.5rem;
	align-items: flex-end;

	.item {
		width: 25%;
		padding-left: 1.5rem;
		margin-bottom: 1.5rem;
		height: 100%;

		&-inner {
			background: $white;
			color: $blueGray;

			&-top {
				min-height: 5rem;
				padding: 1.6rem 1.5rem 0.9rem 5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;

				.icon {
					fill: $blue;
				}
			}

			&-bottom {
				min-height: 4.25rem;
				background: $white;
				padding: 0.75rem 3.5rem 0.75rem 1.35rem;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				position: relative;

				.icon-arrow-right {
					fill: $green;
					position: absolute;
					right: 1.35rem;
					bottom: 1.2rem;
					width: 1.75rem;
					height: 1.75rem;
				}

				&-link {
					background: $gray;
					color: $blue;

					&:hover, &:active, &:focus {
						.item-text {
							text-decoration: underline;
						}
					}
				}
			}

			&:hover:not(&-link),
			&:active:not(&-link),
			&:focus:not(&-link), {
				.item-title {
					text-decoration: underline;
				}
			}
		}

		&-icon {
			width: 3.4rem;
			height: 3.4rem;
			position: absolute;
			left: 1.5rem;
			top: 50%;
			transform: translateY(-50%);

			&-profession {
				width: 3.5rem;
				height: 3.15rem;
				margin-left: 0.5rem;
				margin-top: 0.15rem;
			}

			&-sector {
				width: 3.6rem;
				height: 3.6rem;
			}

			&-education {
				margin-top: 0.15rem;
			}
		}

		&-text {
			font-size: 0.9rem;
			line-height: 1.35;
			font-weight: 500;
			flex-grow: 1;
		}

		&-title {
			font-size: 1.1rem;
			margin-bottom: 0;
			color: $blue;
		}
	}
}

/* text content */

.text-content {
	color: $darkBlueGray;
}

/* news */

.news {
	margin-bottom: -1.5rem;
	align-items: stretch;

	.item {
		width: 33.3333%;
		margin-bottom: 1.5rem;

		.item-inner {
			padding-left: 1.35rem;
			border-left: 2px solid $grayLine;
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		&-title {
			font-size: 0.8rem;
			font-weight: 500;
			color: $blue;
			margin-bottom: 0.5rem;
		}

		&:hover, &:active, &:focus {
			.item-title {
				text-decoration: underline;
			}
		}

		&-date {
			font-size: 0.8rem;
			color: $blueGray;
			font-weight: 400;
			margin-bottom: 0;
		}

		&-category {
			display: inline-block;
			color: $blueGray;
			font-size: 0.7rem;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-weight: 400;
			position: relative;
			padding-left: 0.9rem;
			padding-right: 0.5rem;
			margin-right: 0.35rem;

			&:before {
				content: "";
				display: block;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				position: absolute;
				left: 0.1rem;
				top: 50%;
				margin-top: -4px;
			}

			&:after {
				content: "";
				display: block;
				width: 2px;
				height: calc(100% - 8px);
				background: darken($grayLine, 2%);
				position: absolute;
				right: 0;
				top: 3px;
			}

			&-green {
				&:before {
					background: $labelGreen;
				}
			}

			&-blue {
				&:before {
					background: $labelBlue;
				}
			}

			&-orange {
				&:before {
					background: $labelOrange;
				}
			}
		}
	}
}

/* events */

.events {
	margin-bottom: -1.75rem;
	margin-left: -1.5rem;

	.item {
		width: 50%;
		margin-bottom: 1.75rem;
		padding-left: 1.5rem;

		&-inner, a.item-inner {
			display: flex;
			align-items: stretch;
		}

		&-date-box {
			background: $white;
			padding: 4px;
			min-width: 5.5rem;
			margin-right: 1.35rem;
		}

		&-date-inner {
			padding: 0.6rem 0.5rem;
		}

		&-date {
			font-size: 0.7rem;
			margin-bottom: 0;
			font-weight: 400;

			&-day {
				color: $blueGray;
				display: block;
				margin-bottom: 0.25rem;
			}
		}

		&-date-label {
			display: block;
			padding: 0.25rem 0.7rem;
			font-size: 0.6rem;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-weight: 400;

			&-green {
				background: $labelBgGreen;
				color: $labelGreen;
			}

			&-blue {
				background: $labelBgBlue;
				color: $labelBlue;
			}

			&-orange {
				background: $labelBgOrange;
				color: $labelOrange;
			}
		}

		&-title {
			font-size: 0.8rem;
			font-weight: 500;
			color: $blue;
		}

		&-location {
			color: $blueGray;
			font-size: 0.8rem;
			margin-bottom: 0;
		}

		&:hover, &:active, &:focus {
			.item-title {
				text-decoration: underline;
			}
		}
	}
}

/* partners */

.partners {
	margin-bottom: -0.5rem;

	.item {
		margin-bottom: 0.5rem;
		width: 100%;

		&-inner, a.item-inner {
			background: $white;
			display: flex;
			align-items: stretch;
			text-decoration: none;
		}

		&-img {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 12rem;
			padding: 1.25rem 1.5rem;
			background-image: linear-gradient(rgba($grayLine, 0.7) 67%, rgba(255,255,255,0) 0%);
			background-position: right;
			background-size: 1px 6px;
			background-repeat: repeat-y;

			img {
				width: auto;
			}
		}

		&-text {
			padding: 1.25rem 1.5rem;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		&-title {
			font-size: 0.9rem;
			color: $blue;
			margin-bottom: 0;
			width: 100%;
		}

		&:hover, &:active, &:focus {
			.item-title {
				text-decoration: underline;
			}
		}
	}
}

/* references */

.references {
	margin-bottom: -0.5rem;

	.item {
		margin-bottom: 0.5rem;
		width: 100%;

		&-inner, a.item-inner {
			background: $white;
			display: flex;
			align-items: stretch;
			text-decoration: none;
		}

		&-img {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 12.5rem;
			padding: 1.25rem 1.25rem;
			background-image: linear-gradient(rgba($grayLine, 0.7) 67%, rgba(255,255,255,0) 0%);
			background-position: right;
			background-size: 1px 6px;
			background-repeat: repeat-y;

			img {
				width: auto;
			}
		}

		&-text {
			padding: 1.25rem 1.5rem;
			font-size: 0.7rem;
			flex-grow: 1;
		}

		&-name {
			font-size: 0.9rem;
			color: $blue;
			width: 100%;
			margin-bottom: 0.15rem;
			font-weight: 500;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-position {
			color: $blueGray;
			/*font-size: 0.6rem;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: 0.15rem;*/
		}
	}
}

/* Category */

/* links, downloads */
.category, .links {
	display: flex;
	padding: 1.5rem 0;
	border-top: 2px solid $grayLine;
	margin-bottom: -0.5rem;

	&-head {
		width: 25%;
		font-size: 1.1rem;
		line-height: 1.36;
		font-weight: 600;
		margin-bottom: 0.75rem;
		margin-top: -0.2rem;
		padding-right: 0.75rem;
	}

	&-list {
		width: 75%;
		padding-left: 0.75rem;
		flex: 1;

		&:first-child {
			padding-left: 0;
		}
	}
}

.link {
	display: flex;
	background: $white;
	margin-bottom: 0.5rem;
	color: $blue;
	font-size: 0.7rem;
	padding: 0.3rem 1rem;
	min-height: 2.5rem;
	line-height: 1.3;
	align-items: center;
	justify-content: space-between;

	&-info {
		position: relative;
		padding-left: 1.5rem;
	}

	&-name {
		font-weight: 500;
	}

	&-ext {
		color: $darkBlueGray;
	}

	.icon {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 1.2rem;
		height: 1.2rem;
	}

	&-tags {
		display: flex;
		align-items: center;
		margin-left: 1rem;
	}

	&-tag {
		display: inline-block;
		font-size: 0.6rem;
		line-height: 1.2em;
		padding: 0.3rem 0.6rem;
		background: $gray;
		white-space: nowrap;
		border-radius: 0.75rem;
		font-weight: 400;

		&:not(:first-child) {
			margin-left: 0.3rem;
		}
	}

	&-url {
		font-size: 0.6rem;
	}

	&:hover, &:active, &:focus {
		color: $blue;
		text-decoration: none;

		.link-name, .link-url {
			text-decoration: underline;
		}

		.icon {
			color: $green;
		}
	}
}

/* topic detail content */

.topic-content {
	font-size: 0.8rem;
	margin-top: 2rem;
	color: $darkBlueGray;

	h2 {
		font-size: 1.1rem;
		font-weight: 600;
		margin-bottom: 1rem;

		&:not(:first-child) {
			margin-top: 1.25rem;
		}
	}

	h3, h4, h5, h6 {
		font-size: 0.8rem;
		font-weight: 600;
		margin-bottom: 0.8rem;

		&:not(:first-child) {
			margin-top: 1rem;
		}
	}
}

/* article */

.text-boxes {
	margin-bottom: -0.5rem;
}

.text-box {
	background: $white;
	margin-bottom: 0.5rem;
	display: block;

	&-head {
		font-size: 0.7rem;
		font-weight: 500;
		padding: 0.8rem 1.25rem 0.7rem 1.25rem;
		margin-bottom: 0;
		position: relative;
		color: $baseFontColor;

		a {
			color: $blue;
		}

		&-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.tags {
				padding: 0.5rem 1.25rem 0.4rem 0;
			}
		}
	}

	&-content {
		font-size: 0.7rem;
		padding: 0 1.25rem 0.75rem 1.25rem;
	}

	&.link-item {
		&:hover {
			cursor: pointer;
		}
		&:hover, &:active, &:focus {
			.link-item-link {
				text-decoration: underline;
			}
		}
	}
}

a.text-box {
	.text-box-head {
		color: $blue;
	}
	&:hover, &:active, &:focus {
		text-decoration: none;

		.text-box-head {
			text-decoration: underline;
		}
		.text-box-content {
			text-decoration: none;
		}
	}
}

.accordions {
	margin-bottom: -0.5rem;
}

.accordion {
	background: $white;
	margin-bottom: 0.5rem;
	display: block;

	&-head {
		font-size: 0.7rem;
		font-weight: 500;
		margin-bottom: 0;
		position: relative;
		padding: 0.8rem 1.25rem 0.7rem 2.5rem;
		color: $blue;

		&:before {
			content: "";
			display: block;
			width: 1.1rem;
			height: 1.1rem;
			background: url('../svg/src/plus-blue.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 1rem;
			top: 0.7rem;
		}

		&:hover {
			cursor: pointer;
		}

		&:hover, &:active, &:focus {
			text-decoration: underline;

			&:before {
				background-image: url('../svg/src/plus-green.svg');
			}
		}

		&.active {
			font-weight: 600;
			color: $baseFontColor;

			&:before {
				background-image: url('../svg/src/minus-green.svg');
			}
		}
	}

	&-content {
		font-size: 0.7rem;
		padding: 0 1.25rem 0.75rem 2.5rem;

		a:not([href]), a[href=""] {
			color: $baseFontColor;
			text-decoration: none;
		}
	}

	&-profession {
		.accordion-head {
			font-size: 0.8rem;

			&:before {
				top: 0.8rem;
			}
		}

		.accordion-content {
			padding-top: 0.5rem;
			padding-bottom: 1.25rem;
		}

		.accordion-section-head {
			font-size: 0.8rem;
			margin-bottom: 0.25rem;
		}
	}
}

.contacts {
	margin-left: -1.5rem;
	margin-bottom: -1.5rem;

	.item {
		width: 33.3333%;
		padding-left: 1.5rem;
		margin-bottom: 1.5rem;

		&-inner {
			background: $lightGray;
			border-left: 2px solid $grayLine;
			padding: 1.25rem 1.5rem;
		}

		&-title {
			font-size: 0.9rem;
			font-weight: 600;
			margin-bottom: 0.2rem;
		}

		&-subtitle {
			font-size: 0.6rem;
			color: $blueGray;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: 0.15rem;
		}

		&-contacts {
			font-size: 0.7rem;
			margin-top: 1.25rem;

			p, ul, li {
				margin-bottom: 0;
				line-height: 1.7;
			}

			li {
				padding-left: 0;
			}
		}
	}
}

.entry .contacts {
	margin: 0.5rem 0;
}

.sidebar {
	padding-left: 1.35rem;
	margin: 0;
	border-left: 2px solid $grayLine;

	&-item {
		position: relative;
		padding-left: 1.25rem;
		font-weight: 500;
		font-size: 0.8rem;
		margin-bottom: 0.8rem;

		.icon {
			position: absolute;
			color: darken($darkBlueGray, 10%);
			left: 0;
			top: 0.15rem;
			width: 0.9rem;
			height: 0.9rem;
		}

		a:not(.btn) {
			color: $blue;
			display: block;
			margin-bottom: 0.25rem;
		}

		p {
			margin-bottom: 0;
		}
	}

	&-title {
		display: block;
		font-size: 0.6rem;
		color: $blueGray;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: 0.15rem;
	}

	&-alt {
		.sidebar-item {
			padding-left: 0;
			font-weight: 400;
			margin-bottom: 1rem;
		}
	}
}

.search-results {
	margin-bottom: -0.5rem;
	.item {
		width: 100%;

		&-inner {
			background: $white;
			padding: 2rem;
			margin-bottom: 0.5rem;
			display: block;

			&:hover, &:active, &:focus {
				.item-title {
					text-decoration: underline;
				}
			}
		}

		&-head {
			display: flex;
		}

		&-labels {
			flex: 0 0 8rem;
			text-align: right;
			margin-left: 1.25rem;
		}

		&-type {
			display: inline-block;
			@extend .title;
			font-size: 0.7rem;
			color: $blueGray;
			font-weight: 400;
			position: relative;
			padding-left: 0.7rem;

			&:before {
				content: "";
				display: block;
				width: 5px;
				height: 5px;
				background: $darkGreen;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&-title {
			font-size: 1.1rem;
			color: $blue;
			line-height: 1.3;
			margin-bottom: 0.75rem;
			flex-grow: 1;
		}

		&-text {
			font-size: 0.8rem;
			color: $darkBlueGray;

			em {
				font-weight: 500;
			}
		}
	}
}

.tooltip {
	display: inline-block;
	position: relative;

	&-h2 {
		line-height: 0.9;

		.icon {
			width: 1.2rem;
			height: 1.2rem;
		}
	}

	&-text {
		position: absolute;
		font-size: 0.6rem;
		color: $white;
		background: $blue;
		padding: 0.75rem 1rem;
		text-transform: none;
		font-weight: 400;
		text-align: left;
		width: 20rem;
		top: calc(100% + 12px);
		left: 50%;
		transform: translateX(-50%);
		z-index: 2000;
		letter-spacing: normal;
		border-radius: 5px;
		box-shadow: 0px 6px 8px 0px rgba($darkBlue, 0.15), 0px 12px 27px 0px rgba($darkBlue, 0.1);

		visibility: hidden;
		max-width: 0;
		max-height: 0;
		opacity: 0;
		transition: opacity 0.1s ease-in;
		white-space: nowrap;
		text-overflow: clip;

		&:after {
			@include triangleTop(14px, 8px, $blue);
			position: absolute;
			left: 50%;
			top: -7px;
			transform: translateX(-50%);
		}
	}

	&:hover, &:active, &:focus {
		cursor: pointer;

		.tooltip-text {
			visibility: visible;
			max-width: none;
			max-height: none;
			opacity: 1;
			white-space: normal;
		}
	}
}

.data-row {
	display: flex;
	margin-bottom: 0.5rem;

	&-list {
		margin-bottom: -0.5rem;
	}

	&-title {
		display: block;
		width: 30%;
		color: $grayText;
		font-size: 0.6rem;
		text-transform: uppercase;
		padding-top: 0.1rem;
	}

	&-value {
		display: block;
		flex-grow: 1;
		padding-left: 1.5rem;
	}
}

@media screen and (max-width: $xl) {
}

@media screen and (max-width: $lg) {
	.topics-nav {
		.item-inner-bottom {
			.icon-arrow-right {
				right: 1rem;
			}
		}

		.item-text {
			font-size: 0.8rem;
		}

		.item-icon {
			transform: translate(-0.6rem, -50%) scale(0.8);
		}
	}
}

@media screen and (max-width: $md) {
	.content-sm-hidden {
		display: none !important;
	}

	.teaser-hp {
		min-height: 0;
		padding: 3rem 0;
	}

	.teaser-hp-chart {
		//display: none;
		position: relative;
		right: 0;
		top: 0;
		width: 25rem;
	}

	.topics-nav {
		margin-top: 0;
		align-items: stretch;

		.item {
			width: 50%;
		}

		.item-inner-bottom {
			min-height: 0;
			height: auto;

			.icon-arrow-right {
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.item-inner-top {
			min-height: 0;
			height: auto;
		}
	}

	.search-block {
		padding-top: 2rem;
	}

	.region-map {
		.region-list {
			order: 1;
		}
		.map-wrapper {
			order: 2;
		}
	}

	.region-list {
		margin-top: 0;
	}

	.region-list-bottom {
		text-align: left;
	}

	.links, .category {
		flex-wrap: wrap;

		&-head {
			width: 100%;
			margin-top: 0;
			padding-right: 0;
		}

		&-list {
			width: 100%;
			padding-left: 0;
		}
	}

	.contacts .item {
		width: 50%;
	}

	.sidebar {
		padding-left: 0;
		border-left: none;
		border-bottom: 2px solid $grayLine;
		padding-bottom: 0.25rem;
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: $sm) {
	.teaser-btns {
		flex-basis: auto !important;
		margin-top: 1rem;
		width: 100%;
		justify-content: flex-start;
		margin-left: -1rem;

		.btn {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	.teaser-hp-chart {
		display: none;
	}

	.search-block {
		&-inner {
			flex-wrap: wrap;
		}
		&-text {
			width: 100%;
			margin-bottom: 1rem;
		}
		&-form {
			padding-left: 0;
			width: 100%;
		}
	}

	.link {
		flex-wrap: wrap;
		padding: 0.6rem 1rem;

		&-tags {
			width: 100%;
			margin-left: 0;
			margin-top: 0.75rem;
		}
	}

	.category {
		padding: 1rem 0 1.75rem 0;
	}

	.pager {
		flex-wrap: wrap;

		ul {
			width: 100%;
			order: 3;
			margin-top: 1rem;
		}

		.pager-prev {
			order: 1;
			margin-right: 0.5rem;
		}
		.pager-next {
			order: 2;
			margin-left: 0.5rem;

		}
	}

	.contacts .item {
		width: 100%;
	}

	.news .item {
		width: 100%;
	}

	.events .item {
		width: 100%;
	}
}

@media screen and (max-width: $xs) {
	.breadcrumb-wrapper {
		padding-left: 15px;
	}

	.teaser-tabs {
		padding-top: 1.5rem;
	}

	.partners .item-inner {
		flex-wrap: wrap;
	}

	.partners .item-img {
		width: 100%;
		flex: 0 0 100%;
		background-image: none;
		border-bottom: 1px dashed $grayLine;
	}

	.link-info {
		width: 100%;
		margin-bottom: 0.5rem;
	}

	.data-row {
		flex-wrap: wrap;
		&-title {
			width: 100%;
		}

		&-value {
			width: 100%;
			padding-left: 0;
			padding-top: 0.2rem;
		}
	}
}

@media screen and (max-width: $xxs) {
	.events {
		.item-inner {
			flex-wrap: wrap;
		}
		.item-date-box {
			margin-bottom: 0.5rem;
		}
	}
}
