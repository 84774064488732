/* SVG icons */
svg {
	pointer-events: none;
}
.icon {
	width: 1.25em;
	height: 1.25em;
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}
.icon-porta {
	width: 65px;
}
