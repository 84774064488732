.form-std {
	label {
		font-weight: 400;
		font-size: 0.7rem;
		font-family: $baseFont;
		line-height: 1.4;
		color: $darkBlueGray;
		margin: 0 0 0.35rem 0;
		display: block;
	}

	input[type="text"], input[type="password"], input[type="email"] {
		font-weight: 500;
		font-size: 0.7rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		border: 2px solid $grayLine;
		background: transparent;
		padding: 0.6rem 0.8rem;
		width: 100%;
		filter: none;

		&:focus {
			background: $white;
		}
	}

	input:disabled {
		opacity: .5;

		&:hover {
			cursor: not-allowed;
		}
	}

	textarea {
		font-weight: 500;
		font-size: 0.7rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		resize: none;
		border: 2px solid $grayLine;
		background: transparent;
		padding: 0.6rem 0.8rem;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 12.5rem;
		&:focus {
			background: $white;
		}
	}

	select {
		font-weight: 500;
		font-size: 0.7rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		background: transparent;
		width: 100%;
		padding: 0.6rem 0.8rem;
	}

	option {
		font-weight: 500;
		font-size: 0.7rem;
		font-family: $baseFont;
		line-height: 1.3;
		color: $baseFontColor;
		padding: 0.2rem 0.8rem;
	}

	input.text[required="required"] {
		background-image: url("../img/req.png");
		background-position: right center;
		background-repeat: no-repeat;
	}

	/* Standard */
	.form-group {
		margin: 0 0 1.5rem;
	}

	.form-control {
		margin: 0;
	}

	/* Inline */
	.cell-set {
		display: flex;
		flex-wrap: wrap;
		margin-left: -1.5rem;

		.form-group {
			padding-left: 1.5rem;
		}
	}
	.cell-set-2 .form-group {
		width: 50%;
	}
	.cell-set-3 .form-group {
		width: 33.3333%;
	}
	.cell-set-4 .form-group {
		width: 25%;
	}
	.cell-set-5 .form-group {
		width: 20%;
	}
}

/* Errors */

.help-block {
	color: $mutedRed;
	font-size: 0.7rem;
	margin: 0;

	li {
		margin: 0.35rem 0 0 0;
		padding: 0;
	}
}

.has-error {
	input, textarea, .select-wrapper .select2-selection--single, .select-wrapper .select2-search {
		border-color: lighten($mutedRed, 25%) !important;
	}
}

/* Search forms */

.form-search {
	&-wrap {
		display: flex;

	}

	input.text {
		flex: 1 1 0;
		font-size: 1rem;
		height: 2.3rem;
		background: $white;
		border: none;
		box-shadow: inset 0px 3px 0px 0px rgba($blueGray, 0.15);
		color: $baseFontColor;
		padding: 0.2rem 0.8rem;
	}

	.btn {
		margin-left: 0.5rem;
	}
}

.form-search-block {
	.btn {
		&:hover, &:active, &:focus {
			background-color: $darkGreen;
			border-color: $darkGreen;
		}
	}
}

/* Filter */

.filter {
	padding: 2rem 3.25rem;

	&-wrap {
		border-bottom: 2px solid $grayLine;
	}

	&-search {
		display: flex;

		&-input {
			margin-right: 1rem;
			flex-grow: 1;
			height: 3.25rem;
			font-size: 1.1rem;
			font-weight: 500;
			color: $blue;
			background: $white url('../svg/src/search-blue.svg') 0.8rem 1rem no-repeat;
			background-size: 1.3rem 1.3rem;
			padding: 0.85rem 1.5rem 0.75rem 2.5rem;
			border: 2px solid $grayLine;
			box-shadow: inset 0px 4px 4px 0px rgba(#6a7490, 0.15);

			&::placeholder {
				color: $blue;
				//opacity: 1;
			}
		}

		&-btn {
			height: 3.25rem;
			min-width: 7rem;
		}
	}

	.cell-set {
		margin-bottom: -1.25rem;
	}

	.cell {
		margin-bottom: 1.25rem;
	}

	&-count {
		font-size: 0.7rem;
		color: $darkBlueGray;
		margin-bottom: 0.9rem;
	}
}

/* Contact form */
.form-contact {
	&-wrapper {
		position: relative;
		margin-top: 2rem;
		margin: 2rem -1.6rem 0 -1.6rem;
		background: $white;
		padding: 1.25rem 1.6rem 1.5rem 1.6rem;
	}

	&-close {
		position: absolute;
		right: 1.3rem;
		top: 1.25rem;
		color: $blue;

		.icon {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	textarea {
		height: 10rem;
	}
}

/* Checkbox / radio */

.form-std, .checkboxes {

	/*checkbox*/
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		pointer-events: none;


		& ~ label {
			position: relative;
			padding-left: 1.3rem;
			line-height: 1.2;
			font-family: $baseFont;
			font-size: 0.8rem;
			font-weight: 400;
			display: inline-block;

			&:before {
				content: "";
				display: block;
				border: 2px solid $green;
				position: absolute;
				left: 0;
				top: 0.06rem;
				transition: background 0.07s ease-in;
				width: 15px;
				height: 15px;
				border-radius: 2px;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0.06rem;
				opacity: 0;
				transition: opacity 0.07s ease-in;
				width: 15px;
				height: 15px;
				background: url("../svg/src/check-white.svg") center center no-repeat;
				background-size: 100% 100%;
			}
		}

		&:checked ~ label {
			&:before {
				background: $green;
				border-color: $green;
			}

			&:after {
				opacity: 1;
			}
		}

	}

	/*radio*/
	input[type="radio"] {

		& ~ label {
			position: relative;
			padding-left: 1.3rem;
			line-height: 1.2;
			font-family: $baseFont;
			font-size: 0.8rem;
			font-weight: 400;
			display: inline-block;

			&:before {
				content: "";
				display: block;
				border: 2px solid $green;
				position: absolute;
				left: 0;
				top: 0.08rem;
				transition: background 0.07s ease-in;
				width: 20px;
				height: 20px;
				border-radius: 50%;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0.08rem;
				opacity: 0;
				transition: opacity 0.07s ease-in;
				width: 20px;
				height: 20px;
				background: url("../svg/src/radio-check-white.svg") center center no-repeat;
				background-size: 12px 12px;
			}
		}

		&:checked ~ label {
			&:after {
				opacity: 1;
			}
		}
	}

}

.checkbox-set {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.5rem;

	input[type="checkbox"], input[type="radio"] {
		& ~ label {
			margin-left: 0.5rem;
		}
	}
}

.checkbox-tags {
	input[type="checkbox"] {
		& ~ label {
			background: $blueGray;
			color: $white;
			padding: 0.3rem 0.75rem;
			border-radius: 1rem;
			padding-left: 1.75rem;

			&:before {
				border: 2px solid $white;
				left: 0.55rem;
				top: 0.3rem;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				opacity: 0.5;
			}

			&:after {
				left: 0.62rem;
				top: 0.38rem;
				opacity: 0.5;
			}
		}

		&:checked ~ label {
			background: $green;

			&:before {
				background: $white;
				border-color: $white;
				opacity: 1;
			}

			&:after {
				left: 0.61rem;
				opacity: 1;
				background-image: url("../svg/src/check-green.svg");
			}
		}

	}
}

/* Side filter */
.filter-groups {
	border-top: 2px solid $grayLine;
	margin-bottom: 1rem;
}
.filter-group {
	border-bottom: 2px solid $grayLine;

	// head
	&-name, label.filter-group-name {
		font-weight: 600;
		font-size: 0.8rem;
		color: $blue;
		padding: 0.6rem 0.8rem 0.6rem 0.8rem;
		margin: 0;
		position: relative;
	}

	// checkboxes, inputs
	&-inputs {
		padding: 0.15rem 0 0.8rem 1.75rem;

		&-compact {
			.filter-group-input {
				&:not(:last-child) {
					padding-bottom: 0.25rem;
				}
			}
		}
	}

	&-input {
		&:not(:last-child) {
			padding-bottom: 0.4rem;
		}
	}

	// toggled
	&-toggle {
		.filter-group-name, label.filter-group-name {
			padding-left: 1.75rem;

			&:before {
				content: "";
				display: block;
				width: 1.1rem;
				height: 1.1rem;
				background: url('../svg/src/plus-blue.svg') center center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				left: 0.35rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				cursor: pointer;
			}

			&:hover, &:active, &:focus {
				text-decoration: underline;

				&:before {
					background-image: url('../svg/src/plus-green.svg');
				}
			}
		}

		.filter-group-name.active {
			color: $darkBlue;

			&:before {
				background-image: url('../svg/src/minus-green.svg') !important;
			}
		}
	}
}

/* Select */

select.select {
	height: 2.2rem;
	opacity: 0;
}

.select-wrapper {
	position: relative;

	select {
		height: 2.3rem;
		opacity: 0;
		visibility: hidden;
	}

	.select2 {
		// to be responsive
		width: 100% !important;
	}

	.select2-container {
		// dropdown allways on bottom
		top: 100% !important;

		// opened state
		&--open {
			.select2-selection--single {
				background: $white;
			}

			.select2-selection--multiple {
				background: $white;
				border: 2px solid $grayLine !important;
				border-bottom: none;
				padding-bottom: 0.5rem;
				margin-bottom: -2px;

				.select2-search {
					border: none;
					border-bottom: 2px solid $grayLine !important;

					&:after {
						transform: translateY(-50%) rotate(180deg);
					}
				}

				.select2-selection__choice {
					margin: 0 -0.5rem 0 0.5rem !important;
				}
			}

			.select2-selection__arrow {
				transform: translateY(-50%) rotate(180deg) !important;
			}
		}
	}

	// single select
	.select2-selection--single {
		border-radius: 0;
		padding: 0.7rem 1.75rem 0.6rem 0.8rem;
		height: auto;
		border: 2px solid $grayLine;
		background: transparent;
		font-size: 0.7rem;

		.select2-selection__rendered {
			color: $blue;
			font-size: 0.7rem;
			font-weight: 500;
			line-height: 1.2;
			padding: 0;
			display: block;
			pointer-events: none;
		}

		.select2-selection__arrow {
			content: "";
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			background: url('../svg/src/chevron-down-dark-blue.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			right: 0.55rem;
			top: 50%;
			transform: translateY(-50%);
			transition: transform 0.07s ease-in;

			b {
				display: none;
			}
		}

		.select2-selection__placeholder {
			color: rgba($blue, 0.8);
		}
	}

	// multiselect
	.select2-selection--multiple {
		border-radius: 0;
		padding: 0;
		border: none !important;
		background: transparent;

		.select2-selection__rendered {
			display: flex !important;
			flex-wrap: wrap;
			padding: 0 !important;
			gap: 0.5rem;
		}
	}

	// search input
	.select2-search {
		padding: 0.7rem 1.75rem 0.6rem 0.8rem;
		height: auto;
		border: 2px solid $grayLine;
		order: 1;
		line-height: 0.7rem;
		width: 100%;
		margin: 0;
		position: relative;

		.select2-search__field {
			font-size: 0.7rem;
			font-family: $baseFont;
			font-weight: 500;
			color: $baseFontColor;
			padding: 0;
			margin: 0 !important;
			line-height: 1.3;
		}

		&:after {
			content: "";
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			background: url('../svg/src/chevron-down-dark-blue.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
			transition: transform 0.07s ease-in;
		}
	}

	// search input for single select (input inside dropdown)
	.select2-search--dropdown {
		padding: 0 3rem 0 0;
		border-width: 0 0 1px 0;

		.select2-search__field {
			padding: 0.7rem 0.8rem 0.6rem 0.8rem;
			border: none;
		}
	}

	// tags
	.select2-selection__choice {
		top: 100%;
		order: 2;
		margin: 0 !important;
		padding: 0 0.5rem !important;
		font-size: 0.7rem;
		height: 1.3rem;
		line-height: 1.35rem;
		white-space: nowrap;
		font-weight: 500;
		color: $white;
		background: $green !important;
		border: none !important;
		border-radius: 1rem !important;
		float: none !important;
		display: flex;
		max-width: 100%;

		&:hover, &:active, &:focus {
			background: $blue !important;
		}
	}

	.select2-selection__choice__remove {
		order: 3;
		color: rgba($white, 0.7) !important;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.26rem;
		margin-left: 0.5rem;
		transition: transform 0.05s ease-in;

		&:hover, &:active, &:focus {
			color: $white !important;
			transform: rotate(90deg);
			//transform: scale(1.2) translateX(1px);
		}
	}

	// dropdown
	.select2-dropdown {
		border-radius: 0;
		border: 2px solid $grayLine;
		border-top: none;
	}

	.select2-results__option {
		font-size: 0.7rem;
		margin-bottom: 0;
		padding: 0.35rem 0.75rem;
	}

	.select2-results__option[aria-selected="true"] {
		background: $blue;
		color: $white;
		font-weight: 500;
	}

	.select2-results__option--highlighted[aria-selected] {
		background: $blue;
		color: $white;
	}

	// placeholder
	.select2-container:not(.select2-container--focus) .select2-selection--multiple {
		.select2-search {
			font-size: 0.7rem;
			line-height: 1.3;
			font-weight: 500;
			color: $blue;

			&:before {
				content: attr(data-placeholder);
			}
		}
	}

}

@media screen and (max-width: $md) {
	.filter {
		padding: 1.5rem 1.5rem;
	}
}

@media screen and (max-width: $sm) {
	.filter {
		padding: 1.5rem 0;
	}
}

@media screen and (max-width: $xs) {
	.filter-search {
		flex-wrap: wrap;
	}

	.filter-search-input {
		margin-right: 0;
		height: 3rem;
		background-position: 0.8rem 0.85rem;
		max-width: 100%;
	}

	.filter-search-btn {
		width: 100%;
		margin-top: 0.5rem;
		height: 2.75rem;
	}
}

@media screen and (max-width: $xxs) {
	.form-search {
		&-wrap {
			flex-wrap: wrap;
		}

		.btn {
			width: 100%;
			margin-top: 0.5rem;
			margin-left: 0;
		}
	}
}
