.comment {
	display: block;
	background: rgba($white, 0.5);
	font-size: 0.7rem;
	padding: 0.7rem 1.25rem;
	border-left: 2px solid transparent;
	margin-bottom: 0.5rem;
	color: $grayText;
	text-decoration: none;

	&-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 2.5rem;
		margin-left: -1.5rem;
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $grayText;

		.comment-name {
			text-decoration: underline;
			color: $blue;
		}

		.icon {
			//color: $green;
		}
	}

	&-item {
		display: block;
		width: 20%;
		padding-left: 1.5rem;

		&-narrow {
			width: 10%;
		}
		
		&-wide {
			width: 30%;
		}
	}

	&-name {
		color: $blue;
	}

	&-new {
		border-color: $green;
		background: $white;

		.comment-name {
			font-weight: 500;
		}
	}

	&-label {
		display: inline-block;
		padding: 0.3rem 1rem 0.25rem 1rem;
		background: $blue;
		color: $white;
		border-radius: 2rem;
		line-height: 1.2;

		&-forApproval {
			//background: $labelOrange;
			background: $purple;
		}

		&-forRevision {
			background: $labelOrange;
		}

		&-approved {
			background: $blue;
		}

		&-published {
			background: $green;
		}

		&-rejected {
			background: $red;
		}
	}
}



@media screen and (max-width: $md) {
	.comment {
		&-content {
			flex-wrap: wrap;
			margin-top: -0.25rem;
		}

		&-item {
			width: 100%;
			padding-top: 0.25rem;
			text-align: left !important;
		}

		&-label {
			margin: 0.25rem 0;
		}
	}
}