.footer {
	background: $darkGray;
	color: $white;
	padding: 2.75rem 0 4rem 0;

	/* sticky footer */
	flex-shrink: 0;

	&-logo {
		display: flex;
		width: calc(100% - 0.75rem);
		margin-bottom: 0.8rem;
		margin-top: 0.2rem;
		max-width: 20rem;

		&-link {
			display: block;

			img {
				width: 100%;
				height: auto;
			}
		}

		&-eu {
			width: 78%;
		}
		&-mpsv {
			width: 22%;
		}
	}

	&-flag {
		height: auto;
		
	}

	&-text {
		font-weight: 400;
		color: $white;
		font-size: 0.7rem;
		max-width: 14rem;
		line-height: 1.88;

		a {
			color: $lightBlue;
			.icon {
				margin: 0.25rem 0.3rem 0 0;
			}
		}

		p {
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-head {
		font-size: 0.7rem;
		font-weight: 700;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin-bottom: 0.7em;
	}

	&-nav {
		margin-bottom: 0;

		&-col {
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;

			&:before {
				content: "";
				display: block;
				width: 2px;
				height: 100%;
				background: rgba(#dae1f3, 0.1);
				position: absolute;
				left: -1.75rem;
				top: 0;
			}
		}

		li {
			padding: 0;
			margin: 0 0 0.25rem 0;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: $lightBlue;
				font-size: 0.7rem;
				font-weight: 600;
				position: relative;
				padding-left: 0.75rem;
				line-height: 1.8;

				&:before {
					content: "";
					display: block;
					width: 1rem;
					height: 1rem;
					background: url('../svg/src/chevron-right-light-blue.svg') center center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: -0.35rem;
					top: -0.05rem;
				}

				&:hover, &:active, &:focus {
					&:before {
						background-image: url('../svg/src/chevron-right-white.svg');
					}
				}
			}
		}
	}

	&-social-nav {
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.85rem;

		li {
			margin-left: 0.4rem;
			padding: 0;
		}

		a {
			color: $lightBlue;

			&:hover, &:active, &:focus {
				color: $white;
			}
		}

		.icon-facebook, .icon-instagram, .icon-twitter, .icon-linkedin {
			width: 1.35rem;
			height: 1.35rem;
		}
		.icon-youtube {
			width: 1.4rem;
			height: 1.4rem;
			margin-top: 0.05rem;
		}

		.icon-pinterest {
			width: 1.15rem;
			height: 1.15rem;
			margin-top: 0.1rem;
		}

		.icon-slideshare {
			width: 1.25rem;
			height: 1.25rem;
			margin-top: 0.05rem;
		}
	}
}

.porta {
	display: block;
	color: $lightBlue;

	&:hover, &:active, &:focus {
		color: $white;
	}

	&-wrap {
		margin: auto 0 0 0;
		text-align: right;
	}
}

@media screen and (max-width: $lg) {
	.footer-text {
		margin-bottom: 1.5rem;
	}

	.footer-nav-col-lg:before {
		display: none;
	}

	.footer-social-nav {
		margin-top: 2rem;
		margin-left: -1rem;

		li {
			margin-left: 0.9rem;
		}
	}

	.porta-wrap {
		text-align: left;
	}
}

@media screen and (max-width: $sm) {
	.footer-nav-col {
		margin-top: 1.5rem;

		&:before {
			display: none;
		}
	}
}