a {
	color: inherit;
	text-decoration: none;
	transition: color 0.07s ease-in;

	&:hover, &:active, &:focus {
		text-decoration: underline;
		color: inherit;
	}
}

.link-underlined {
	text-decoration: underline;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $gray;
	}
}

.link-chevron {
	font-size: 0.6rem;
	color: $blue;
	font-weight: 500;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	position: relative;
	padding-left: 0.75rem;
	display: inline-block;
	line-height: 1.5;

	&:before {
		content: "";
		display: block;
		width: 1rem;
		height: 1rem;
		background: url('../svg/src/chevron-right-blue.svg') center center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: -0.25rem;
		top: -0.1rem;
	}

	&:hover, &:active, &:focus {
		color: $blue;

		&:before {
			background-image: url('../svg/src/chevron-right-green.svg');
		}
	}
}

.sh-trig-chevron {
	font-size: 0.6rem;
	text-transform: uppercase;
	color: $blue;
	letter-spacing: 0.1em;
	font-weight: 500;
	line-height: 1.5;
	padding-left: 1.1rem;
	position: relative;
	display: inline-block;

	&:before {
		content: "";
		display: block;
		width: 1.2rem;
		height: 1.2rem;
		background: url('../svg/src/chevron-down-blue.svg') center center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: -0.25rem;
		top: -0.2rem;
		transition: transform 0.07s ease-in;
	}

	&:hover {
		cursor: pointer;
	}

	&:hover, &:active, &:focus {
		&:before {
			background-image: url('../svg/src/chevron-down-green.svg');
		}
		text-decoration: underline;
	}

	&.active {
		&:before {
			transform: rotate(180deg);
		}
	}
}

.title {
	font-weight: 500;
	font-size: 0.7rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}


.title-small {
	@extend .title;
	font-size: 0.6rem;
}

/* Heading */

h1, .h1 {
	font-weight: 600;
	color: $headingColor;
	font-size: 1.8rem;
	line-height: 1.33;
	margin: 0 0 0.5em 0;
}
h2, .h2 {
	font-weight: 600;
	color: $headingColor;
	font-size: 1.3rem;
	line-height: 1.4;
	margin: 0 0 0.75em 0;
}
h3, .h3 {
	font-weight: 600;
	color: $headingColor;
	font-size: 1.1rem;
	line-height: 1.4;
	margin: 0 0 0.75em 0;
}
h4, .h4 {
	font-weight: 600;
	color: $headingColor;
	font-size: 0.9rem;
	line-height: 1.5;
	margin: 0 0 1em 0;
}
h5, .h5 {
	font-weight: 500;
	color: $headingColor;
	font-size: 0.9rem;
	line-height: 1.5;
	margin: 0 0 1em 0;
}
h6, .h6 {
	font-weight: 500;
	color: $headingColor;
	font-size: 0.9rem;
	line-height: 1.5;
	margin: 0 0 1em 0;
}

/* Paragraphs */

p {
	margin: 0 0 1em 0;
}
address {
	font-style: normal;
}
cite {
	font-style: normal;
}

/* Lists */

ul, ol {
	list-style: none;
	margin: 0 0 1em 0;
}

ul li {
	margin: 0 0 0.25em 0;
	padding: 0 0 0 1em;
}

.list-unstyled li {
	padding: 0;
	background: none;
}

.list-checked li, .topic-content ul li, .entry .list-checked li, .entry.topic-content ul li {
	position: relative;
	padding-left: 2.5rem;
	margin-bottom: 0.55rem;

	&:before {
		content: "";
		display: block;
		width: 1.3rem;
		height: 1.3rem;
		background: url('../svg/src/check-circle-outline-green.svg') center center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: 0.5rem;
		top: -0.1rem;
	}
}

ol {
	counter-reset: counter;
	margin-left: 0;
	*margin-left: 54px;
}

ol > li {
	margin: 0 0 0.5em 0;
	padding: 0 0 0 1em;
	*list-style-type: decimal;
	counter-increment: counter;
	position: relative;

	&:before {
		color: $gray;
		content: counter(counter) ". ";
		position: absolute;
		left: 0;
		top: 0;
	}
}

dl {
	margin: 0 0 1em 0;
}

dt {
	font-weight: bold;
}

.text-small {
	font-size: 0.7em;
}
.text-big {
	font-size: 1.3em;
}

.text-light {
	font-weight: 200;
}
.text-medium {
	font-weight: 500;
}
.text-bold {
	font-weight: 600;
}
strong {
	font-weight: 600;
}

.text-uppercase {
	text-transform: uppercase;
}
.text-nowrap {
	white-space: nowrap;
}

.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.color-gray {
	color: $blueGray !important;
}

.color-dark-gray {
	color: $darkBlueGray !important;
}

.color-green {
	color: $green !important;
}

.color-text-green {
	color: $textGreen !important;
}

.color-red {
	color: $red !important;
}

.color-blue {
	color: $blue !important;
}

.color-white {
	color: $white !important;
}

.color-dark-blue {
	color: $darkBlue !important;
}

.f-l {
	float: left;
}
.f-r {
	float: right;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $gray;
	margin: 1em 0;
	padding: 0;
}

sup,
sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}
sup {
	bottom: 1ex;
}
sub {
	top: 0.5ex;
}

figure {
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}

	figcaption {
		margin-top: 0.75rem;
		font-size: 0.7rem;
		color: $darkBlueGray;
	}
}

.entry {
	font-size: 0.8rem;
	line-height: 1.5;

	p, ul, ol, li, table {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2 {
		margin-top: 1.8rem;

		&:first-child {
			margin-top: 0;
		}
	}

	h3 {
		margin-top: 1.25rem;

		&:first-child {
			margin-top: 0;
		}
	}

	h4, h5, h6 {
		margin-top: 1.25rem;

		&:first-child {
			margin-top: 0;
		}
	}

	figure, table {
		margin: 1.25rem 0 1.25rem 0;

		&:first-child {
			margin-top: 0.25rem;
		}

		&:last-child {
			margin-bottom: 0.25rem;
		}
	}

	ul > li {
		position: relative;
		padding-left: 2.5rem;
		margin-bottom: 0.75rem;

		&:before {
			content: "";
			position: absolute;
			left: 1.3rem;
			top: 0.42rem;
			width: 6px;
			height: 6px;
			display: block;
			background: $blue;
			border-radius: 50%;
		}
	}

	ol > li {
		position: relative;
		padding-left: 2.5rem;
		margin-bottom: 0.75rem;

		&:before {
			content: counter(counter);
			position: absolute;
			left: 0.9rem;
			top: 0.1rem;
			width: 1rem;
			height: 1rem;
			font-size: 0.6rem;
			font-weight: 500;
			line-height: 1rem;
			text-align: center;
			display: block;
			color: $blue;
			background: $grayLine;
			border-radius: 50%;
		}
	}

	.btn {
		margin-bottom: 0.25rem;
	}

	a:not(.btn) {
		color: $blue;
	}
}

/* laptop < 1370 */
@media screen and (max-width: $xl) {
	
}

@media screen and (max-width: $lg) {
	
}

@media screen and (max-width: $md) {
}

/* mobile < 768 */
@media screen and (max-width: $sm) {
	.text-sm-left {
		text-align: left !important;
	}
}

@media screen and (max-width: $xs) {
	.item-list .item {
		width: 100% !important;
	}

	.entry {
		ul li {
			padding-left: 2rem;

			&:before {
				left: 0.8rem;
			}
		}

		ol li {
			padding-left: 2rem;

			&:before {
				left: 0.4rem;
			}
		}
	}
}

@media screen and (max-width: $xxs) {
}
