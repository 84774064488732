html {
	height: 100%;
	font-size: $baseFontSize;
	position: relative;

	&:not(.scrollable-document) {
		overflow-x: hidden;
	}
}

body {
	font-family: $baseFont;
	font-size: $baseFontSize;
	line-height: $baseLineHeight;
	color: $baseFontColor;
	background: $white;
	font-weight: $baseFontWeight;
	margin: 0;
	padding: 0;
	min-height: 100%;
	max-width: 100vw;
	position: relative;

	/* sticky footer */
	display: flex;
	flex-direction: column;

	&:not(.sticky-wrap) {
		overflow-x: hidden;
	}
}

/* Box sizing */

*,
*:after,
*:before {
	box-sizing: border-box;
}

/* Forms */

form {
	display: block;
}
fieldset {
	border: none;
}
label {
	font-weight: bold;
}
input,
select,
button {
	vertical-align: top;
}
a,
a:hover,
a:active,
a:focus {
	outline: 0;
}

/* Various */

img {
	max-width: 100%;
	height: auto;
}

.last {
	margin-bottom: 0 !important;
}

.sh-content, .btn.sh-content {
	display: none;
}

.content-hidden {
	display: none !important;
}

.hidden {
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
}

.display-block {
	display: block;
}

/* Item lists */

.item-list {
	margin: 0 0 0 -1.5em;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.item {
		padding-left: 1.5em;
	}

	a.item-inner {
		display: block;
		text-decoration: none;
	}

	&.item-list-1 .item {
		width: 100%;
	}

	&.item-list-2 .item {
		width: 50%;
	}

	&.item-list-3 .item {
		width: 33.33333%;
	}

	&.item-list-4 .item {
		width: 25%;
	}

	&.item-list-center {
		justify-content: center;
	}
}

/* Layout */

@for $i from 0 through 5 {
	.m-t-#{$i} {
		margin-top: #{$i}em !important;
	}
	.m-b-#{$i} {
		margin-bottom: #{$i}em !important;
	}
	.m-l-#{$i} {
		margin-left: #{$i}em !important;
	}
	.m-r-#{$i} {
		margin-right: #{$i}em !important;
	}
	.p-t-#{$i} {
		padding-top: #{$i}em !important;
	}
	.p-b-#{$i} {
		padding-bottom: #{$i}em !important;
	}
	.p-l-#{$i} {
		padding-left: #{$i}em !important;
	}
	.p-r-#{$i} {
		padding-right: #{$i}em !important;
	}
}

.m-b-0-25 {
	margin-bottom: 0.25em !important;
}
.m-b-0-5 {
	margin-bottom: 0.5em !important;
}
.m-b-0-75 {
	margin-bottom: 0.75em !important;
}
.m-b-1-25 {
	margin-bottom: 1.25em !important;
}
.m-b-1-5 {
	margin-bottom: 1.5em !important;
}
.m-b-1-75 {
	margin-bottom: 1.75em !important;
}
.m-b-2-5 {
	margin-bottom: 2.5em !important;
}

.m-t--0-25 {
	margin-top: -0.25rem !important;
}

.m-t-0-25 {
	margin-top: 0.25em !important;
}
.m-t-0-5 {
	margin-top: 0.5em !important;
}
.m-t-0-75 {
	margin-top: 0.75em !important;
}
.m-t-1-25 {
	margin-top: 1.25em !important;
}
.m-t-1-5 {
	margin-top: 1.5em !important;
}
.m-t-1-75 {
	margin-top: 1.75em !important;
}
.m-t-2-5 {
	margin-top: 2.5em !important;
}

.m-l-0-25 {
	margin-left: 0.25em;
}
.m-l-0-5 {
	margin-left: 0.5em;
}
.m-l-0-75 {
	margin-left: 0.75em;
}
.m-l-1-5 {
	margin-left: 1.5em;
}
.m-l-2-5 {
	margin-left: 2.5em;
}

.m-r-0-25 {
	margin-right: 0.25em;
}
.m-r-0-5 {
	margin-right: 0.5em;
}
.m-r-0-75 {
	margin-right: 0.75em;
}
.m-r-1-5 {
	margin-right: 1.5em;
}
.m-r-2-5 {
	margin-right: 2.5em;
}

.p-b-0-25 {
	padding-bottom: 0.25em !important;
}
.p-b-0-5 {
	padding-bottom: 0.5em !important;
}
.p-b-0-75 {
	padding-bottom: 0.75em !important;
}
.p-b-1-5 {
	padding-bottom: 1.5em !important;
}
.p-b-2-5 {
	padding-bottom: 2.5em !important;
}

.p-t-0-5 {
	padding-top: 0.5em !important;
}
.p-t-1-5 {
	padding-top: 1.5em !important;
}
.p-t-2-5 {
	padding-top: 2.5em !important;
}

.no-margin {
	margin: 0;
}
.no-padding {
	padding: 0;
}

/* Mixins */

@mixin bgImageWrapper() {
	display: block;
	position: relative;
	overflow: hidden;
}

@mixin bgImage() {
	display: block;
	width: auto;
	max-width: none;
	min-width: 100%;
	min-height: 100%;
	position: absolute;
}

@mixin bgImageCenterTop() {
	left: 50%;
	top: 0;
	transform: translateX(-50%);
}

@mixin bgImageCenter() {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin boxShadowHover() {
	box-shadow: 0px 13px 18px 0px rgba($blue, 0.15), 0px 4px 7px 0px rgba($blue, 0.15);
	&:hover,
	&:active {
		box-shadow: 0px 13px 18px 0px rgba($blue, 0.2), 0px 4px 7px 0px rgba($blue, 0.2);
	}
}

@mixin triangleTop($width, $height, $color) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 #{$width/2} #{$height} #{$width/2};
	border-color: transparent transparent $color transparent;
	position: absolute;
}

@mixin triangleRight($width, $height, $color) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: #{$height/2} 0 #{$height/2} #{$width};
	border-color: transparent transparent transparent $color;
	position: absolute;
}

@mixin triangleBottom($width, $height, $color) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: #{$height} #{$width/2} 0 #{$width/2};
	border-color: $color transparent transparent transparent;
	position: absolute;
}

@mixin triangleLeft($width, $height, $color) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: #{$height/2} #{$width} #{$height/2} 0;
	border-color: transparent $color transparent transparent;
	position: absolute;
}

/* laptop < 1370 */
@media screen and (max-width: $xl) {

}

@media screen and (max-width: $lg) {
}

@media screen and (max-width: $md) {
	body {
		overflow-x: hidden;
	}

	.m-b-md-1 {
		margin-bottom: 1rem !important;
	}
}

/* mobile < 768 */
@media screen and (max-width: $sm) {
	.m-b-sm-0 {
		margin-bottom: 0 !important;
	}
	.m-b-sm-0-5 {
		margin-bottom: 0.5rem !important;
	}
	.m-b-sm-1 {
		margin-bottom: 1rem !important;
	}
	.m-t-sm-1 {
		margin-top: 1rem !important;
	}
}

@media screen and (max-width: $xs) {
	.item-list .item {
		width: 100% !important;
	}
}

@media screen and (max-width: $xxs) {
}
