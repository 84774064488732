.flash-message {
	font-size: 0.7rem;
	font-family: $baseFont;
	font-weight: 400;
	background: $darkBlue;
	color: $white;
	width: 100%;
	padding: 0.7rem 2.5rem 0.7rem 1rem;
	margin: 0 0 1rem 0;
	display: inline-block;
	vertical-align: top;
	position: relative;

	.close {
		font-size: 1.5rem;
		font-family: Arial, sans-serif;
		font-weight: 400;
		width: 1rem;
		height: 1rem;
		padding: 0;
		line-height: 18px;
		text-align: center;
		color: rgba($white, 0.7);
		border: none;
		display: block;
		position: absolute;
		right: 17px;
		top: 15px;
		background: none;
	}
	
	&:hover, &:active, &:focus {
		.close {
			color: $white;
		}
	}
}

.flash-message.alert-danger {
	background: $mutedRed;
	color: $white;
}

.flash-message.alert-success {
	background: $green;
}

.modal-content .flash-message {
	margin: 0 0 20px 0;
}

#flash-messages {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
}
#flash-messages .flash-message {
	margin: 0 auto;
	width: 100%;
	display: block;
}
