.modal-box {
	display: none;
	background: $white;
	
	&-opened {
		display: block;
	}
}

.modal-content {
	padding: 1.15rem 1.5rem 1.5rem 1.5rem;
}

/* Message comments */

.modal-comment {
	width: 35rem;
	max-width: 100%;
}

.modal-metric-comment {
	width: 35rem;
	max-width: 100%;
}
