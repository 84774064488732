.document-scrollbar {
	height: 0.75rem;
	background: darken($gray, 20%);
	width: 30rem;
	border-radius: 0.5rem;
	position: absolute;
	left: 0;
	top: 0;
	user-select: none;

	&-container {
		width: 100vw;
		height: 0.75rem;
		background: darken($gray, 5%);
		margin-bottom: 0.25rem;
		position: sticky;
		left: 0;
		top: 0;
		z-index: 910;
		overflow: hidden;
		user-select: none;
		opacity: 0;
		max-height: 0;
		visibility: hidden;

		&.document-scrollbar-active {
			opacity: 1;
			max-height: none;
			visibility: visible;
		}

		&:hover {
			cursor: pointer;
		}
	}
}


.scrollable-document {
	body {
		max-width: none;
		overflow-x: auto;
		display: grid;

		.container-fluid {
			width: calc(100vw - 15px);
			margin-left: 0;
		}
		.container {
			margin-left: 0;
			margin-right: 0;
			position: relative;
			left: calc(50vw - 8px);
			transform: translate(-50%);
		}
	}

	&.document-scrolled {
		th.row-scrollable {
			top: 0.75rem !important;
		}
	}
}