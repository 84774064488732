.messages {
	&-counter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 0;

		&-text {
			font-size: 0.7rem;
			color: $darkBlueGray;
			position: relative;
			padding-left: 0.7rem;
			margin-bottom: 0;

			&:before {
				content: "";
				display: block;
				width: 5px;
				height: 5px;
				border-radius: 5px;
				background: $green;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 2.5rem;
	background: rgba($white, 0.5);
	font-size: 0.7rem;
	padding: 0.7rem 0.9rem;
	border-left: 2px solid transparent;
	margin-bottom: 0.5rem;
	color: $blue;
	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $blue;

		.message-name {
			text-decoration: underline;
			color: $blue;
		}

		.icon {
			//color: $green;
		}
	}

	span {
		display: inline-block;
	}

	&-text {
		display: flex;
	}

	&-date, &-from, &-to {
		color: $darkBlueGray;
	}

	&-date {
		width: 4rem;
	}

	&-from, &-to {
		padding-left: 0.75rem;
		width: 12rem;
	}

	&-name {
		padding-left: 1rem;
	}

	&-new {
		border-color: $green;
		background: $white;

		.message-name {
			font-weight: 500;
		}
	}

	.icon {
		margin-left: auto;
		width: 1.1rem;
		height: 1.1rem;
		flex: 0 0 1.1rem;
	}
}

.message-content {
	padding-right: 1rem;
}

.message-comment {
	border-left: 2px solid $grayLine;
	border-right: 2px solid $grayLine;
	margin-bottom: 1.35rem;
	font-size: 0.7rem;

	&-text {
		margin-bottom: 0.7rem;
		padding: 0 1.5rem;
		font-size: 0.7rem;
		line-height: 1.5;
	}

	&-attachments {
		margin-bottom: 0.9rem;
		padding: 0 1.5rem;
	}

	&-details {
		padding: 0 1.5rem;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-right: 2rem;
		position: relative;

		p {
			margin-bottom: 0;
			color: $baseFontColor;
			font-weight: 500;
		}

		.btn {
			position: absolute;
			right: -2px;
			bottom: 0;
		}
	}

	@for $i from 1 through 3 {
		&-#{$i} {
			margin-left: #{$i}rem;
		}
	}
}

@media screen and (max-width: $md) {
	.message {
		&-text {
			flex-wrap: wrap;
		}

		&-name {
			padding-left: 0;
			width: 100%;
			margin-top: 0.25rem;
		}

		.icon {
			flex: 0 0 1rem;
		}
	}

	.message-sidebar {
		padding-left: 0;
		border-left: none;
		border-bottom: 2px solid $grayLine;
		margin-bottom: 1rem;
	}
}