$baseFont: 'avenir-next', sans-serif;
//$baseFont: 'Source Sans Pro', sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 400;

$baseFontColor: #14244e;
$headingColor: #011545;

$darkBlue: #011545;
$black: $darkBlue;
$white: #ffffff;
$gray: #eceff6;
$darkGray: #242424;
$lightGray: #f6f7fb;
$blueGray: #6a7490;
$mediumBlueGray: #5a6276;
$darkBlueGray: #495269;

$blue: #004A98;
$mutedBlue: #1b50cf;
$brightBlue: #00347B;
$green: #00C77C;
$lightGreen: #dbf2e9;
$darkGreen: #04925d;
$purple: #7330DD;

$textGreen: #01915e;
$red: #cd0027;
$mutedRed: #e74d4d;

$iconLightBlue: #9FCBF9;
$lightBlue: #9FCBF9;

$grayLine: #dae1f3;
$darkerGrayLine: #b8c5e7;
$darkGrayLine: #b4b9c7;
$grayText: #76829d;

$labelBgGreen: #e6f8f1;
$labelBgBlue: #e9f0f9;
$labelBgOrange: #f5f0e6;

$labelGreen: $green;
$labelBlue: #2973cf;
$labelOrange: #bb8104;

$overlay: rgba(#001E61, 0.3);
$lightOverlay: rgba(#001E61, 0.2);

$xl: 1370px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 420px;