/* Regions list */

.region {
	&-list {
		margin-bottom: 1rem;
		margin-top: 2rem;

		li {
			margin: 0;
			padding: 0;
		}

		&-bottom {
			margin-top: 0;
			text-align: center;

			li {
				border-top: 2px solid $grayLine;
			display: inline-block;
			}

			.region-item {
				width: auto;
			}
		}
	}

	&-item {
		display: inline-block;
		width: 100%;
		font-size: 0.8rem;
		font-weight: 500;
		color: $blue;
		padding: 0.75rem 0.5rem 0.65rem 1.5rem;
		line-height: 1.3;
		position: relative;
		border-bottom: 2px solid $grayLine;

		&:before {
			content: "";
			display: block;
			width: 1.2rem;
			height: 1.2rem;
			background: url('../svg/src/chevron-right-blue.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 0.25rem;
			top: 0.65rem;
		}

		&:hover, &:active, &:focus {
			text-decoration: underline;
			color: $blue;

			&:before {
				background-image: url('../svg/src/chevron-right-green.svg');
			}
		}
	}
}

.region-map {
	display: flex;
	flex-direction: column;
}

.map {
	width: 100%;
	height: auto;
	pointer-events: auto;
	margin-top: 0.5rem;

	&-tooltip {
		background: $blue;
		color: $white;
		font-weight: 500;
		text-align: center;
		padding: 0.7rem 1rem 0.6rem 1rem;
		line-height: 1.2;
		border-radius: 5px;
		display: inline-block;
		box-shadow: 0px 6px 8px 0px rgba($darkBlue, 0.15), 0px 12px 27px 0px rgba($darkBlue, 0.1);
		margin: 0.5rem;
		position: relative;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.1s ease-in;
		pointer-events: none;

		&:after {
			@include triangleBottom(14px, 7px, $blue);
			position: absolute;
			left: 50%;
			bottom: -7px;
			transform: translateX(-50%);
		}
	}

	&-region {
		fill: transparent;
		stroke: transparent;
		stroke-width: 2;
		stroke-linecap:round;
		stroke-linejoin:round;
		transition: all 0.1s ease-in;

		&:hover, &:active, &:focus {
			fill: $lightGreen;
			stroke: $green;
			cursor: pointer;

			.map-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		foreignObject {
			pointer-events: none;
		}
	}
}

.map-region-bg {
	fill: $white;
	stroke: $grayLine;
	stroke-width: 2;
	stroke-linecap:round;
	stroke-linejoin:round;
}