.loader {
	position: relative;
	width: 1.5rem;
	height: 1.5rem;

	&:before, &:after {
		content: "";
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		margin: 3px;
		border: 2px solid $white;
		border-radius: 50%;
		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $white $white transparent transparent;
		z-index: 10000;
	}

	&:before {
		animation-delay: -0.4s;
	}

	&:after {
		animation-delay: -0.15s;
	}

	&-box {
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		margin-top: -0.75rem;
		margin-left: -0.75rem;
		left: 50%;
		top: 50%;
	}

	&-overlay {
		display: none;
		z-index: 9990;
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba($black, 0.15);
	}
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}