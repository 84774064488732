.entry table, .highcharts-data-table, .verification-table {
	width: 100%;
	line-height: 1.5;

	th {
		font-weight: 400;
		font-size: 0.6rem;
		text-transform: uppercase;
		border-bottom: 2px solid $grayLine;
		padding: 0.6rem 1rem 0.5rem 1rem;
		background: $lightGray;
		color: $darkBlueGray;
		text-align: left;
		vertical-align: top;

		&:not(:last-child) {
			background-image: linear-gradient(rgba($grayLine, 0.7) 67%, rgba(255,255,255,0) 0%);
			background-position: right;
			background-size: 1px 6px;
			background-repeat: repeat-y;
		}
	}

	td {
		border-bottom: 1px solid $grayLine;
		padding: 0.6rem 1rem 0.5rem 1rem;
		font-size: 0.6rem;
		color: $darkBlueGray;
		background: $white;

		&:not(:last-child) {
			background-image: linear-gradient(rgba($grayLine, 0.7) 67%, rgba(255,255,255,0) 0%);
			background-position: right;
			background-size: 1px 6px;
			background-repeat: repeat-y;
		}
	}

	& > tr:last-child, tbody > tr:last-child {
		td, th {
			border-bottom: none;
		}
	}
}