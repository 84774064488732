/*@font-face {
    font-family: 'avenir-next';
    src: url('../font/avenir-next-lt-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}*/

@font-face {
    font-family: 'avenir-next';
    src: url('../font/avenir-next-lt-medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}

/*@font-face {
    font-family: 'avenir-next';
    src: url('../font/avenir-next-lt-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;

}*/

@font-face {
    font-family: 'avenir-next';
    src: url('../font/avenir-next-lt-demi.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'avenir-next';
    src: url('../font/avenir-next-lt-demi.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;

}