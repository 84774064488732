$progressCircleSize: 4rem;
$progressCircleWidth: 0.2rem;
$emptyBarBg: $gray;
$filledBarBg: $blue;

.progress {
	&-overlay {
		z-index: 9990;
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba($black, 0.15);

		&-inner {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&-box {
		background: $white;
		position: relative;
		padding: 1.5rem;
		min-width: $progressCircleSize;
		min-height: $progressCircleSize;
		border-radius: 50%;
	}

	&-circle {
		position: relative;
		padding: 0;
		width: $progressCircleSize;
		height: $progressCircleSize;
		background-color: $emptyBarBg;
		border-radius: 50%;
		line-height: $progressCircleSize;
		float: left;

		&:after{
			border: none;
			position: absolute;
			top: $progressCircleWidth;
			left: $progressCircleWidth;
			text-align: center;
			display: block;
			border-radius: 50%;
			width: $progressCircleSize - ($progressCircleWidth * 2);
			height: $progressCircleSize - ($progressCircleWidth * 2);
			background-color: white;
			content: " ";
		}

		&.progress-circle-over50 {
			.progress {
				&-left-clipper {
					clip: rect(auto,auto,auto,auto);
				}

				&-left-side {
					position: absolute;
					clip: rect(0, $progressCircleSize, $progressCircleSize, $progressCircleSize/2);
					background-color: $filledBarBg;
					border-radius: 50%;
					width: $progressCircleSize;
					height: $progressCircleSize;
					display: block;
				}
			}
		}
	}

	&-label {
		position: absolute;
		line-height: $progressCircleSize;
		width: $progressCircleSize;
		text-align: center;
		display: block;
		color: $filledBarBg;
		z-index: 2;
		font-size: 0.8rem;
		transform: translate(0.15rem);

		&:after {
			content: "%";
			padding-left: 0.1rem;
		}
	}

	&-left-clipper {
		border-radius: 50%;
		width: $progressCircleSize;
		height: $progressCircleSize;
		position: absolute;
		clip: rect(0, $progressCircleSize, $progressCircleSize, $progressCircleSize/2);
	}

	&-right-side {
		position: absolute;
		clip: rect(0, $progressCircleSize/2, $progressCircleSize, 0);
		width: $progressCircleSize;
		height: $progressCircleSize;
		border-radius: 50%;
		border: ($progressCircleWidth * 1.2) solid $filledBarBg;
		box-sizing: border-box;
		transition: transform 0.2s linear;
	}

	&-left-side {
		display: none;
	}
}

.progress-circle[data-value="0"] .progress-value {
	display: none;
}

@for $i from 0 through 100 {
	.progress-circle[data-value="#{$i}"] .progress-value {
		transform: rotate(#{$i * 3.6}deg);
	}
}

