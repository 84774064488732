/* colors */
$colors: #7330DD, #E23DD6, #3CD8FF, #FF3E48, #59C382, #FFE003, #013CCA, #FF7300, #FF8BDA, #007257, #FFB800, #AE29DD, #0088F2, #D3B994, #E40037, #9ADDED, #8B4700, #FFBDCC, #009550, #AE0048;

$i: 0;
@each $color in $colors {
	.highcharts-color-#{$i} {
		fill: $color;
		stroke: $color;

		.highcharts-legend-item-text:before {
			background-color: $color;
		}
	}
	$i: $i + 1;
}

// chart only (not applied to map)
.metric-wrapper {
	// svg element
	.highcharts {
		&-root {
			.highcharts-legend {
				display: none !important;
			}
		}
	}
}

.highcharts {
	&-container {
		font-family: $baseFont;
	}

	// svg element
	&-root {
		pointer-events: auto;
	}

	// legend
	&-legend {
		position: relative !important;
		top: auto !important;
		left: 0 !important;

		div, span {
			position: relative !important;
			top: auto !important;
			left: 0 !important;
		}

		&-item {
			display: inline-block;
			padding-left: 1rem;
			font-size: 0.6rem;
			color: $blueGray;
			margin-bottom: 0.4rem;
			line-height: 1.6;

			&:hover {
				cursor: pointer;
			}

			&-text {
				position: relative;
				display: inline-block;
				padding-left: 0.9rem;

				&:before {
					content: "";
					display: block;
					width: 0.5rem;
					height: 0.5rem;
					position: absolute;
					left: 0;
					top: 0.2rem;
				}
			}

			&-hidden {
				opacity: 0.5 !important;
			}
		}

		&-toggle {
			@extend .sh-trig-chevron;
			margin: 0.5rem 0 0.5rem 1rem;
		}

		&-note {
			padding-left: 1rem;
			font-size: 0.6rem;
			color: $blueGray;
			margin-bottom: 0.6rem;
			margin-top: 0.15rem;
		}
	}

	// tooltips
	&-tooltip {
		span {
			padding: 0.1rem 0.6rem 0.2rem 0.25rem;
			font-family: $baseFont;
			color: $white;
		}

		&-box {
			fill: $blue !important;
			stroke: transparent;
			stroke-width: 0;
			fill-opacity: 0.9;
		}
	}

	// axis
	&-axis-title {
		font-family: $baseFont;
		color: $blueGray;
		font-size: 0.7rem;
	}

	&-axis-labels {
		span {
			font-family: $baseFont;
			color: $blueGray;
			font-size: 0.6rem;
		}
	}

	&-xaxis-labels {
		span {
			padding-top: 0.35rem;
		}
	}

	&-xaxis {
		display: none;
	}

	// series
	&-line-series {
		.highcharts-graph {
			stroke-width: 2px; 
		}
	}

	// zones - prediction
	&-zone-graph-1 {
		stroke-dasharray: 8, 5 !important;
		stroke-width: 2px;
	}

	&-plot-band {
		fill: $green !important;
		fill-opacity: 0.1 !important;
	}

	// stacks labels
	&-stack-labels {
		span {
			font-family: $baseFont;
			color: $blueGray;
			font-size: 0.6rem;
			//padding-left: 0.5rem;
			transform: translate(-0.15rem, 0.5rem) rotate(-90deg);
			transform-origin: 0 0;
		}
	}

	// html table
	&-data-table {
		max-width: 100%;
		overflow-x: auto;
		margin-top: 0.5rem;

		table {
			width: 100%;

			th {
				font-size: 0.55rem;
				padding: 0.5rem 0.5rem 0.4rem 0.5rem;
			}

			td {
				padding: 0.5rem 0.5rem 0.4rem 0.5rem;
			}
		}
	}
}

.metric-wrapper, .highcharts-container {
	overflow: visible !important;
}

/* Geographical map */

.highcharts-map {
	.highcharts {
		&-map-series {
			.highcharts-point {
				stroke: $white;
			}
		}
		&-label {
			text {
				font-family: $baseFont;
				color: $white;
				fill: $white;
			}

			&:nth-child(11) {
				text {
					transform: translateX(-20px);
				}
			}
		}
	}
}