/* Data verification forms */

.form-verification {
	// editable inputs
	input[type="text"] {
		color: $blueGray;
		font-weight: normal;
		font-size: 0.6rem;
		background: $white;
		min-width: 4rem;

		// wide inputs for text entry
		&[data-expanded-input="1"] {
			min-width: 10rem;
		}
	}

	// disabled inputs
	input:disabled, textarea:disabled {
		border: none !important;
		padding: 0 0.25rem;
		opacity: 1;
		font-size: 0.7rem;
		min-width: 0;
		background: transparent;
	}

	.select-wrapper-default {
		min-width: 11rem;

		select {
			appearance: none;

			background: $white url('../svg/src/chevron-down-dark-blue.svg') right 0.35rem center no-repeat;
			background-size: 1.25rem 1.25rem;
			color: $baseFontColor;
			border: 2px solid $grayLine;
			padding: 0.6rem 1.6rem 0.6rem 0.5rem;
			margin: 0;
			font-weight: 400;
			font-size: 0.7rem;
			font-family: $baseFont;
			line-height: 1.3;
			cursor: inherit;
			min-width: 13.5rem;
		}
	}

	.select-wrapper {
		min-width: 8rem;
	}

	// disabled selects
	.select2-container--disabled {
		opacity: 0.7;
	}

	textarea {
		height: auto;
		color: $baseFontColor;
		overflow: hidden;
		resize: vertical;
		width: auto;
	}

	// selects, multiselects
	.select2-selection.select2-selection--multiple {
		min-width: 10rem;
	}
	.select2-selection.select2-selection--single {
		min-height: 2.5rem;
	}
	.select2-search, .select-wrapper .select2-selection--single {
		background: $white !important;
	}

	// checkboxes
	input[type="checkbox"] {
		& ~ label {
			width: 20px;
			height: 20px;

			&:before {
				border: 2px solid $blue;
				transition: background 0.07s ease-in, border-color 0.07s ease-in;
				width: 20px;
				height: 20px;
				border-radius: 50%;
			}

			&:after {
				left: 0;
				top: 0.09rem;
				transition: opacity 0.07s ease-in;
				width: 20px;
				height: 20px;
				background: url("../svg/src/check-blue.svg") center center no-repeat;
				background-size: 16px 16px;
				opacity: 1;
			}
		}

		&:checked ~ label {
			&:before {
				border-color: $green;
				background-color: $green;
			}

			&:after {
				background-image: url("../svg/src/check-white.svg");
			}
		}
	}
}

.verification {
	&-list {
		a {
			color: $blue;
		}

		&-head {
			margin-bottom: 0;
			font-size: 1.1rem;
			color: $blue;
			text-transform: none !important;
		}

		th, td {
			font-size: 0.7rem !important;
			background: $white !important;
			color: $baseFontColor !important;
		}
	}

	// state
	&-state {
		font-size: 0.8rem;
		line-height: 1.3;
		font-weight: 500;

		.title-small {
			display: block;
		}

		&-value {
			display: block;
		}
	}

	// control buttons
	&-controls {
		display: flex;
		justify-content: space-between;
		margin: 0 0 2rem 0;

		.btn {
			margin-left: 0.5rem;
		}
	}

	// verification table
	&-table {
		width: auto;
		table-layout: auto;
		max-width: none;
		border-collapse: separate;
		margin-top: 0.75rem;

		label.error {
			margin-top: 0.5rem;
			color: $red;
		}

		&-wrap {
			position: relative;
		}

		&-topic {
			font-weight: 500;
			color: $baseFontColor;
			display: inline-block;

			&-col {
				min-width: 16rem;
			}

			&-num {
				display: inline-block;
				margin-right: 1rem;
			}
		}

		thead {
		}

		tbody {

		}

		label {
			font-size: 0.6rem;
			margin-bottom: 0;
		}

		th {
			background-color: $gray;

			&.row-fixed {
				position: sticky;
				top: 0;
				z-index: 900;
				box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
			}
		}

		td {
			vertical-align: top;
			padding: 0.5rem;
			border-bottom-width: 4px;
			border-bottom-color: $gray;

			&.col-fixed {
				position: sticky;
				left: 0;
				box-shadow: 3px 0px 10px 0px rgba(0,0,0,0.1);
				z-index: 800;

				.col-fixed-content {
					display: flex;
					align-items: center;

					&-item {
						padding: 0 0.75rem;
					}
				}
			}

			&.col-wide {
				min-width: 12rem;
			}

			&.col-bg {
				background-color: #F7F9FD;
			}

			&.col-text {
				padding: 0.5rem 0.75rem;
			}
		}

		&-title {
			@extend .title;
			font-size: 0.6rem !important;
			color: $mediumBlueGray;
		}
	}
}