.btn {
	font-size: 0.6rem;
	display: inline-block;
	vertical-align: middle;
	border: 2px solid $blue;
	background: $blue;
	color: $white;
	font-weight: 500;
	text-transform: uppercase;
	font-family: $baseFont;
	letter-spacing: 0.15em;
	line-height: 1.2;
	padding: 0.75rem 1.5rem 0.65rem 1.5rem;
	text-decoration: none;
	transition: color 0.07s ease-in, background 0.07 ease-in, border-color 0.07s ease-in;

	&:hover, &:active, &:focus {
		background: $green;
		border-color: $green;
		text-decoration: none;
		color: $white;
	}
}

.btn.btn-border {
	border: 2px solid $grayLine;
	background: none;
	color: $blue;

	&:hover, &:active, &:focus {
		background: $blue;
		border-color: $blue;
		color: $white;
	}
}

.btn.btn-teaser {
	background: transparent;

	border: 2px solid rgba($lightBlue, 0.5);
	&:hover, &:active, &:focus {
		background: $white;
		border-color: $white;
		color: $blue;
	}

	&.btn-loader {
		position: relative;

		&:before {
			content: "";
			display: block;
			opacity: 0;
			pointer-events: none;
			width: 0.8rem;
			height: 0.8rem;
			background: url('../svg/src/spinner-white.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 1.25rem;
			top: 50%;
			margin-top: -0.4rem;
			animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		}

		&:hover, &:active, &:focus {
			&:before {
				background-image: url('../svg/src/spinner-blue.svg');
			}
		}

		&.btn-loading {
			padding-left: 2.5rem;

			&:before {
				opacity: 1;
			}
		}
	}
}

@keyframes btnLoader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.btn.btn-icon {
	padding: 0.3rem 0.3rem 0.3rem 0.3rem;

	.icon {
		width: 1rem;
		height: 1rem;
	}
}

.btn-box {
	background: none;
	border: 2px solid $grayLine;
	color: $blue;
	padding: 0.35rem;
	margin-left: 0.5rem;
	width: 1.8rem;
	height: 1.8rem;
	text-align: center;
	display: block;
	position: relative;

	&:hover, &:active {
		background: $blue;
		border-color: $blue;
		color: $white;
	}

	.icon {
		width: 0.9rem;
		height: 0.9rem;
	}
	
	.icon-close {
		width: 1.2rem;
		height: 1.2rem;
		margin-top: -0.15rem;
		margin-left: -0.15rem;
	}

	.icon-cz-map {
		width: 1.3rem;
		height: 1.3rem;
		margin-top: -0.15rem;
		margin-left: -0.15rem;
	}
}

.btn-set {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.75rem;

	.btn {
		margin-left: 0.75rem;
		margin-bottom: 0.75rem;
	}

	&-actions {
		.btn {
			width: 100%;
			text-align: center;
		}
	}
}