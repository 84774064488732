.header {
	height: 4.5rem;
	padding: 0;
	background: $blue;
	color: $white;
	border-bottom: 2px solid rgba(#dae1f3, 0.1);
	position: relative;
	text-align: center;
	z-index: 1100;

	&-logo {
		display: block;
		position: absolute;
		left: 1.7rem;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1150;
	}
}

/* Main navigation */
.main-nav {
	margin: 0 auto;
	display: inline-block;

	ul {
		margin: 0;

		li {
			padding: 0 1.4rem;
			margin: 0;
			background: none;
			display: inline-block;
		}

		&:not(:focus-within):not(:hover) .main-nav-item {
			color: $white;
		}

	}

	&-menu {
		margin-left: 0.5rem;
	}

	&-item {
		color: rgba($white, 0.5);
		display: block;
		height: calc(4.5rem - 2px);
		line-height: 4.5rem;
		letter-spacing: 0.1em;
		text-decoration: none;
		white-space: nowrap;
		padding: 0;
		position: relative;
		font-size: 0.7rem;
		text-transform: uppercase;
		font-weight: 500;

		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background: $green;
			position: absolute;
			left: 0;
			bottom: -2px;
			transform: scaleX(0);
			transition: transform 0.05s ease-in;
		}

		&:hover, &:active, &:focus {
			color: $white;
			text-decoration: none;

			&:after {
				transform: scaleX(1);
			}
		}

		&.active {
			&:after {
				transform: scaleX(1);
			}
		}

		.icon {
			vertical-align: middle;
			margin: -0.17rem 0.1rem 0 0.15rem;
		}

		.icon-menu {
			display: inline-block;
			width: 1.3rem;
			height: 1.3rem;
			color: $iconLightBlue;
			margin-left: -0.2rem;
		}
		.icon-close {
			display: none;
			width: 1.3rem;
			height: 1.3rem;
			fill: $green;
			margin-left: -0.2rem;
		}

		&.dropdown-trig-active {
			.icon-menu {
				display: none;
			}
			.icon-close {
				display: inline-block;
			}
		}
	}
}

.menu-trigger {
	border: none;
	width: 2.75rem;
	height: 2.75rem;
	position: absolute;
	right: -7px;
	top: 0.6em;
	display: none;
	text-align: center;
	padding: 0.5em;
	z-index: 1100;

	.icon.icon-menu {
		fill: $white;
		width: 1.75rem;
		height: 1.75rem;
		display: block;
	}
	.icon.icon-close {
		fill: $green;
		width: 1.75rem;
		height: 1.75rem;
		display: none;
	}
}

.dropdown {
	display: none;
	position: absolute;
	left: 50%;
	//top: calc(100% + 2px);
	top: 100%;
	transform: translateX(-50%);
	text-align: left;
	background: $brightBlue;
	padding: 2rem 2rem;

	&-trig {
		position: relative;

		&:before {
			@include triangleTop(24px, 14px, $brightBlue);
			position: absolute;
			left: calc(50% - 12px);
			transform: translateY(0);
			top: 100%;
			transition: none;
			visibility: hidden;
			opacity: 0;
		}

		&-active {
			&:before {
				visibility: visible;
				opacity: 1;
				//transform: translateY(calc(-100% + 2px));
				transform: translateY(-100%);
				transition: all 0.1s ease-in;
			}

			&:after {
				//display: none;
			}	
		}
	}

	a {
		color: $lightBlue;
	}

	// menu dropdown 
	&-menu {
		width: 27.5rem;
	}

	// search dropdown
	&-search {
		left: auto;
		right: 0;
		transform: none;
		width: 40rem;
	}

	&-wrap {
		position: relative;
	}

	// columns
	&-cols {
		display: flex;
	}

	ul {
		margin: 0;

		li {
			padding: 0;
			margin-bottom: 0.2rem;
			display: block;
		}
	}

	a {
		padding-left: 1.1rem;
		line-height: 1.8;
		position: relative;
		font-size: 0.7rem;
		display: inline-block;

		&:before {
			content: "";
			display: block;
			width: 1rem;
			height: 1rem;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 0;
			top: 0.1em;
		}
	}

	// column
	&-col {
		ul {
			margin: -0.4rem 0;
		}

		&-main {
			a {
				color: $white;
				@extend .title;
				font-weight: 500;

				&:before {
					background-image: url('../svg/src/chevron-right-white.svg');
				}
			}

			border-right: 2px solid rgba($white, 0.2);
			margin-right: 1.75rem;
			padding-right: 1.8rem;
		}

		&-right {

			ul {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				width: 50%;
			}

			a {
				font-weight: 400;

				&:before {
					background-image: url('../svg/src/chevron-right-light-blue.svg');
				}
			}

		}
	}
}

.secondary-nav {
	margin: 0;
	position: absolute;
	right: 1.25rem;
	top: 0;

	& > ul {
		margin: 0;

		& > li {
			margin: 0;
			padding: 0;
			display: inline-block;

			& > a {
				padding: 0 0.6rem;
			}
		}
	}

	&:not(:focus-within):not(:hover) {
		.user-trig-btn:before {
			border-color: $white;
		}

		.main-nav-item {
			color: $white;
		}
	}
}

.user {
	&-name {
		display: flex;
		align-items: center;
		font-size: 0.7rem;
		line-height: 1.7;
		font-weight: 500;
		margin-bottom: 1.35rem;
	}

	&-avatar {
		border-radius: 50%;
		overflow: hidden;
		width: 2.5rem;
		height: 2.5rem;
		flex: 0 0 2.5rem;
		margin-right: 1rem;
		margin-top: -0.15rem;
	}

	&-dropdown {
		padding: 1.5rem 1.5rem 1.25rem 1.5rem;
		width: 13rem;
		margin-left: -3.8rem;

		left: auto;
		right: 0;
		transform: translateX(4rem);

		ul li {
			width: 100%;
		}
	}

	&-nav {
		li {
			margin-bottom: 0.25rem;

			&:last-child {
				margin-bottom: 0;
			}

			&.user-nav-line {
				border-top: 2px solid rgba($white, 0.2);
				margin-top: 0.6rem;
				padding-top: 0.35rem;
			}
		}

		a {
			padding-left: 0;
			display: block;
			padding-right: 1.5rem;

			.icon {
				margin: 0.15rem 0.35rem 0 0;
			}
		}

		&-number {
			display: inline-block;
			background: $green;
			color: $white;
			text-align: center;
			padding: 0.2rem 0;
			border-radius: 50%;
			width: 1.1rem;
			height: 1.1rem;
			font-size: 0.65rem;
			line-height: 1;
			position: absolute;
			right: 0;
			top: 0.05rem;
		}
	}

	&-trig {
		text-decoration: none;
		height: calc(4.5rem - 2px);

		&:after {
			display: none;
		}


		&:hover, &:active, &:focus {
			.user-trig-btn:before {
				border-color: $white;
			}
		}

		&-btn {
			transform: translateY(-0.1rem);
			font-size: 0.5rem;
			letter-spacing: 0;
			font-weight: 500;
			position: relative;
			text-decoration: none;
			position: relative;
			display: inline-block;
			width: 1.1rem;
			height: 1.1rem;
			text-align: center;
			line-height: 1.1rem;

			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				border: 2px solid rgba($white, 0.5);
				border-radius: 50%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				transition: border-color 0.07s ease-in;
			}

			&-alert {
				&:after {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					background: $green;
					border: 2px solid $blue;
					border-radius: 50%;
					position: absolute;
					right: -2px;
					top: -2px;
				}
			}
		}

		&.dropdown-trig-active {
			.user-trig-btn:before {
				border-color: $green;
			}
		}
	}
}

.search-trig {
	&:after {
		display: none;
	}

	.icon-search {
		width: 1.3rem;
		height: 1.3rem;
		margin: -0.1rem 0 0 0;
	}
}

@media screen and (max-width: $lg) {
	.main-nav {
		margin-left: 5rem;

		& > ul > li {
			padding: 0 0.8rem;
		}
	}

	.secondary-nav ul li > a {
		padding: 0 0.25rem;
	}
}

@media screen and (max-width: $md) {
	.menu-trigger {
		display: block;
	}

	.header {
		height: auto;
		min-height: 4rem;
		width: 100%;

		.logo {
			top: 1.25em;
			left: 1.25em;
		}
	}

	.main-nav {
		background: $brightBlue;
		z-index: 1050;
		width: 100vw;
		height: 100vh;
		left: 100vw;
		margin: 0;
		top: 4rem;
		padding: 1.5em;
		position: absolute;
		transition: left 0.25s ease-in;
		text-align: left;

		ul {
			width: 100%;
			margin-bottom: 1rem;

			li {
				display: block;
				width: 100%;
				padding: 0;

			}
		}
	}

	.main-nav, .dropdown-menu {
		ul li a {
			line-height: 3.3;
			font-weight: 500;
			height: auto;
			font-size: 0.8em;
			text-transform: uppercase;
			text-decoration: none;
			color: $white;
			display: block;
			width: 75%;

			&:after {
				display: none;
			}

			&:hover, &:active, &:focus {
				color: $white;
			}
		}
	}

	.secondary-nav {
		right: 0;
		padding-right: 2.5rem;

		& > ul > li > a {
			padding: 0 0.6rem;
			height: 4rem;
			line-height: 4rem;
		}
	}

	.menu-open {
		overflow: hidden;
		height: calc(100vh + 4rem);

		.main-nav {
			overflow: scroll;
			left: -15px;
		}

		.menu-trigger {
			.icon-menu {
				display: none;
			}
			.icon-close {
				display: block;
			}
		}
	}

	.dropdown {
		width: 100vw;
		margin: 0;
		transform: none;
		right: -15px;
		left: auto;

		&-trig {
			//position: static;
		}

		a {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
	}

	.dropdown-menu {
		display: block !important;
		position: relative;
		left: 0;
		top: 0;
		transform: none;
		background: none;
		border-top: 2px solid rgba(#dae1f3, 0.1);
		margin-top: 1rem;
		padding: 1rem 0 0 0;
		width: auto;
	}

	.user {
		&-wrap {
			position: static;
		}

		&-dropdown {
			//width: 100vw;
			//margin-left: 0;
			//transform: translateX(3.7rem);
		}

		&-nav {
			a {
				font-size: 0.8rem;
				line-height: 2.25rem;

				.icon {
					margin-top: 0.75rem;
				}
			}
		}

		&-name {
			font-size: 0.8rem;
		}
	}
}

@media screen and (max-width: $xs) {
	.header-logo {
		left: 15px;
	}
}
@media screen and (max-width: 440px) {
	.header-logo {
		width: 130px;
	}
}

@media screen and (max-width: 380px) {
	.header-logo {
		width: 110px;
	}
}