.tabs {
	position: relative;
}

.tab-nav ul {
	margin: 0 0 0 -1px;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	li {
		list-style-type: none;
		padding: 0;
		background: none;
		margin: 0;

		a {
			display: block;
			text-align: center;
			text-decoration: none;
			outline: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			&:hover, &:active, &:focus {
				&:not(.selected) {
					text-decoration: underline;
				}
			}
		}
	}
}

.tabs-std-wrap {
	border-bottom: 2px solid $grayLine;
}
.tab-nav-std ul {
	margin-left: -2rem;

	li {
		margin-left: 2rem;

		a {
			display: inline-block;
			position: relative;
			font-family: $baseFont;
			color: $blue;
			white-space: nowrap;
			line-height: 2.5rem;
			@extend .title;
			font-weight: 500;

			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background: $green;
				position: absolute;
				left: 0;
				bottom: -2px;
				transform: scaleX(0);
				transition: transform 0.05s ease-in;
			}

			&.selected {
				color: $darkBlue;
				&:after {
					transform: scaleX(1);
				}
			}
		}
	}
}

.tab-nav-big ul {
	li {
		flex: 1;

		a {
			padding: 0.75rem 1rem;
			background: $white;
			color: $blue;
			font-weight: 600;
			font-size: 1.3rem;
			letter-spacing: 0.01em;
			height: 4.5rem;

			&.selected {
				background: $gray;
				color: $headingColor;

				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 4px;
					background: $green;
					position: absolute;
					left: 0;
					top: -4px;
				}
			}
		}

		h1 {
			font-weight: 600;
			font-size: 1.3rem;
			margin-bottom: 0;
			color: $headingColor;
		}
	}
}

.tab-content {
	.tab {
		float: left;
		width: 100%;
		padding: 30px;
		background: $white;
		display: none;

		&.selected {
			position: static !important;
			display: block;
		}
	}
}

@media screen and (max-width: $md) {
	.tab-nav-big ul li a {
		font-size: 1.1rem;
		height: 4rem;
	}
	.tab-nav-big ul li a h1 {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: $xs) {
	.tab-nav-big ul li a, .tab-nav-big ul li a h1 {
		font-size: 1rem;
	}
}

@media screen and (max-width: $xxs) {
	.tab-nav-big ul li a {
		font-size: 0.9rem;
		height: 3.75rem;

		h1 {
			font-size: 0.9rem;
		}
	}
}