/* Topics selection */
// select element
.topics-select {
	width: 100%;
	border: none;
	height: 5.2rem;
	margin: 1.3rem 1.5rem 1rem 1.5rem;
	opacity: 0;
}

.topics-selects {
	position: relative;
	background: $gray;

	.select2 {
		// to be responsive
		width: 100% !important;
	}

	// container
	.select2-container {
		// dropdown allways on bottom
		top: auto !important;

		&--open {
			z-index: 1050;

			.select2-search:after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	// search input
	.select2-selection {
		background: $gray;
		border: none !important;
		border-radius: 0 !important;
		padding: 1.3rem 1.5rem 1rem 1.5rem;
		position: relative;

		&__rendered {
			display: flex !important;
			flex-wrap: wrap;
			padding: 0 !important;
		}
	}

	.select2-search {
		width: 100%;
		height: 3.25rem;
		order: 1;
		position: relative;
		font-size: 1.1rem;
		font-weight: 500;
		color: $blue;
		background: $white url('../svg/src/search-blue.svg') 0.8rem 1rem no-repeat;
		background-size: 1.3rem 1.3rem;
		padding: 0.85rem 3rem 0.75rem 2.5rem;
		border: 2px solid $grayLine;
		box-shadow: inset 0px 4px 4px 0px rgba(#6a7490, 0.15);

		&:after {
			content: "";
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			background: url('../svg/src/chevron-down-dark-blue.svg') center center no-repeat;
			background-size: 100% 100%;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
			transition: transform 0.07s ease-in;
		}
	}

	.select2-search__field {
		width: 100%;
		font-size: 1.1rem;
		line-height: 1.4;
		font-weight: 500;
		color: $blue;
		margin: 0 !important;
	}

	// tags
	.select2-selection__choice {
		top: 100%;
		order: 2;
		margin: 0.4rem 0.5rem 0 0 !important;
		padding: 0 0.5rem !important;
		font-size: 0.7rem;
		height: 1.3rem;
		line-height: 1.35rem;
		white-space: nowrap;
		font-weight: 500;
		color: $white;
		background: $green !important;
		border: none !important;
		border-radius: 1rem !important;
		float: none !important;
		display: flex;
		max-width: 100%;
		position: relative;

		&:hover, &:active, &:focus {
			background: $blue !important;
		}
	}

	.select2-selection__choice__text {
		order: 1;
		padding-right: 0.5rem;
		word-wrap: break-word;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
	}

	.select2-selection__choice__link {
		order: 2;
		color: rgba($white, 0.7);
		text-transform: uppercase;
		font-weight: 400;
		padding: 0 0.5rem 0 0.5rem;
		position: relative;
		font-size: 0.6rem;
		line-height: 1.35rem;

		&:hover, &:active, &:focus {
			color: $white;
			text-decoration: underline;
		}

		&:before {
			content: "";
			display: block;
			width: 1px;
			height: 100%;
			background-image: linear-gradient(rgba($white, 0.7) 50%, rgba(255,255,255,0) 0%);
			background-position: right;
			background-size: 1px 2px;
			background-repeat: repeat-y;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.select2-selection__choice__remove {
		order: 3;
		color: rgba($white, 0.7) !important;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.25rem;
		transition: transform 0.05s ease-in;

		&:hover, &:active, &:focus {
			color: $white !important;
			transform: rotate(90deg);
			//transform: scale(1.2) translateX(1px);
		}
	}

}

//dropdown
.topics-select-dropdowns {
	position: relative;
}

.topics-select-dropdown {
	&-wrapper {
		width: 100%;
		flex: 0 0 100%;
		position: absolute;
		top: 100%;
		background: $white;
		padding: 1rem 1.5rem 1.5rem 1.5rem;
	}

	&-btns {
		text-align: right;
		padding-top: 1rem;
		border-top: 2px solid $grayLine;
		margin-top: 1.2rem;

		.btn:not(:last-child) {
			margin-right: 1rem;
		}
	}

	&.topics-select-dropdown-cols-3 {
		.select2-results__option {
			width: 33.33333%;
		}
	}

	.select2-container {
		position: relative !important;
		left: 0 !important;
		top: 0 !important;
		width: 100% !important;
	}

	.select2-dropdown {
		width: 100% !important;
		left: 0 !important;
		top: 0 !important;
		border: none;
		background: $white;
		position: relative;
		border-radius: 0 !important;
	}

	.select2-results {
		&__options {
			display: flex;
			flex-wrap: wrap;
			max-height: none;
		}

		&__option {
			width: 25%;
			font-size: 0.7rem;
			font-weight: 400;
			background: none !important;
			color: $baseFontColor !important;
			position: relative;
			padding-left: 1.25rem;
			padding-right: 1rem;
			margin: 0;

			&:before {
				content: "";
				display: block;
				border: 2px solid $darkGrayLine;
				position: absolute;
				left: 0;
				top: 0.4rem;
				transition: background 0.07s ease-in;
				width: 15px;
				height: 15px;
				border-radius: 2px;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0.4rem;
				opacity: 0;
				transition: opacity 0.07s ease-in;
				width: 15px;
				height: 15px;
				background: url("../svg/src/check-white.svg") center center no-repeat;
				background-size: 100% 100%;
			}

			&:hover, &:active, &:focus {
			}

			&[aria-selected="true"] {
				font-weight: 500;

				&:before {
					background: $green;
					border-color: $green;
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}
}

// placeholder
.select2-container:not(.select2-container--focus) {
	.select2-search {
		font-size: 1rem;

		&:before {
			content: attr(data-placeholder);
		}
	}
}

// 2 cols
.topics-selects-2 {
	display: flex;
	flex-wrap: wrap;

	.topics-select-wrapper {
		width: 50%;

		&:nth-child(1) {
			.select2-selection {
				padding: 1.3rem 0.75rem 1rem 1.5rem;
			}
		}

		&:nth-child(2) {
			.select2-selection {
				padding: 1.3rem 1.5rem 1rem 0.75rem;
			}
		}
	}

}

// closed modal
.topics-select-dropdown-wrapper {
	display: none;
}

// opened as modal
.select-opened {
	.topics-selects {
		z-index: 1050;
	}

	.topics-select-dropdown-wrapper {
		display: block;
	}

	.topics-select-wrapper:not(.topics-select-wrapper-active) {
		.select2-search {
			background-color: $gray;
		}
	}
}

// in teaser
.topics-selects.topics-selects-teaser {
	margin-top: 1.25rem;
	background: transparent;

	.select2-container {
		.select2-selection {
			background: transparent;
			padding: 0;
		}

		&--open {
			.select2-selection {
				border-bottom: -2px;
				background: $white;
			}

			.select2-selection__choice {
				margin-left: 1rem !important;
			}
		}
	}

	.topics-select {
		height: 5.2rem;
		margin: 1.25rem 0 0 0;
	}

	.select2-selection__choice {
		&:hover, &:active, &:focus {
			background: $baseFontColor !important;
		}
	}

}

@media screen and (max-width: $lg) {
	.topics-select-dropdown .select2-results__option {
		width: 33.333%;
	}

	.topics-select-dropdown-wrapper {
		
	}
}

@media screen and (max-width: $md) {
	.filter-topics-wrap {
		padding-left: 0;
		padding-right: 0;

		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}

@media screen and (max-width: $sm) {
	.topics-select-dropdown .select2-results__option {
		width: 50% !important;
	}

	.topics-selects-2 .topics-select-wrapper {
		width: 100%;

		&:nth-child(1) {
			.select2-selection {
				padding: 1.3rem 1.5rem 0.5rem 1.5rem;
			}
		}

		&:nth-child(2) {
			.select2-selection {
				padding: 0.5rem 1.5rem 1rem 1.5rem;
			}
		}
		
	}
}

@media screen and (max-width: $xs) {
	.topics-select-dropdown .select2-results__option {
		width: 100% !important;
	}

	.topics-selects .select2-search {
		height: 2.75rem;
		padding: 0.6rem 3rem 0.5rem 2.5rem;
		background-position: 0.8rem 0.7rem;
	}

	.topics-select-dropdown-btns {
		padding-top: 0.75rem;
	}
	
	.topics-select-dropdown-wrapper {
		padding: 1rem 1.5rem 1rem 1.5rem;
	}
}