.topics {
	margin-bottom: -0.5rem;
}

.topic {
	display: flex;
	align-items: stretch;
	text-decoration: none;
	margin-bottom: 0.5rem;

	&:hover, &:active, &:focus {
			text-decoration: none;

			.topic-name {
				text-decoration: underline;
			}
		}

	&-inner {
		flex-grow: 1;
		display: flex;
		align-items: center;
		min-height: 2.5rem;
		background: rgba($white, 0.5);
		font-size: 0.7rem;
		padding: 0.7rem 0.9rem;
	}

	&-name {
		font-weight: 500;
		font-size: 0.7rem;
		color: $blue;
	}
}



@media screen and (max-width: $md) {
	.message {
		&-text {
			flex-wrap: wrap;
		}

		&-name {
			padding-left: 0;
			width: 100%;
			margin-top: 0.25rem;
		}

		.icon {
			flex: 0 0 1rem;
		}
	}

	.message-sidebar {
		padding-left: 0;
		border-left: none;
		border-bottom: 2px solid $grayLine;
		margin-bottom: 1rem;
	}
}